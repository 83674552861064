var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-default page-channels"},[_c('v-app',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.complaints,"loading":_vm.loading,"footer-props":_vm.rowsPerPageItems,"items-per-page":10,"sort-by":_vm.sortBy,"options":_vm.options,"sort-desc":_vm.sortDesc,"server-items-length":_vm.totalItems,"item-key":"id"},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:options":function($event){_vm.options=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('img',{staticStyle:{"width":"42px","height":"32px","border-radius":"5px","object-fit":"cover","margin-right":"10px"},attrs:{"src":_vm.getBroadcastPreview(item.broadcast)}}),_c('div',{staticClass:"table-sub-title table-sub-title--short"},[_vm._v(" "+_vm._s(item.broadcast.name)+" ")])])]}},{key:"item.link",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":_vm.getBroadcastLink(item.broadcast)}},[_vm._v(_vm._s(_vm.getBroadcastLink(item.broadcast)))])]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.getUserName(item.user)))])]}},{key:"item.complaint",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',{staticClass:"strong"},[_vm._v("Report Type: ")]),_vm._v(" "+_vm._s(item.reportType)+" ")]),(item.comment)?_c('div',[_c('span',{staticClass:"strong"},[_vm._v("Comment: ")]),_vm._v(" "+_vm._s(item.comment)+" ")]):_vm._e()]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }