<template>
  <div class="auth-block-wrap">
    <div v-if="currentBlock === 'Login'" class="auth-block">
      <div class="auth-block-title">Login</div>
      <form @submit.prevent="submitLogin">
        <div class="form-group">
          <label for="email">Email</label>
          <input id="email" v-model="loginData.email" type="text" />

          <div v-if="submitted && $v.loginData.email.$error">
            <div v-if="!$v.loginData.email.required" class="msg-error">Email is required</div>
            <div v-if="!$v.loginData.email.email" class="msg-error">Email must be valid</div>
          </div>
        </div>

        <div class="form-group">
          <label for="password">Password</label>
          <input id="password" v-model="loginData.password" type="password" />

          <div v-if="submitted && $v.loginData.password.$error">
            <div v-if="!$v.loginData.password.required" class="msg-error">Password is required</div>
          </div>
        </div>

        <button type="submit" class="btn-submit">Login</button>
      </form>
      <div class="flex-wrap">
        <button class="btn-forgotten-pass" @click="currentBlock = 'Forgotten password'">Forgotten password?</button>
      </div>
    </div>

    <div v-if="currentBlock === 'Forgotten password'" class="auth-block">
      <div class="auth-block-title">Forgotten password</div>
      <div class="auth-block-des">Enter your email and we'll send you a link to get back into your account.</div>
      <form @submit.prevent="resetPassord">
        <div class="form-group">
          <label for="forget-email">Email</label>
          <input id="forget-email" v-model="forgotEmail" type="text" />
        </div>
        <div v-if="submittedForgot && $v.forgotEmail.$error">
          <div v-if="!$v.forgotEmail.required" class="msg-error">Email is required</div>
          <div v-if="!$v.forgotEmail.email" class="msg-error">Email must be valid</div>
        </div>
        <button type="submit" class="btn-submit">Send Login Link</button>
      </form>

      <div class="flex-wrap">
        <button class="btn-forgotten-pass" @click="currentBlock = 'Login'">Back To Login</button>
      </div>
    </div>

    <div v-if="currentBlock === 'Forgotten password msg'" class="auth-block">
      <div class="auth-block-title">Forgotten password</div>
      <div class="auth-block-des">We sent an email to {{ forgotEmail }} with a link to get back into your account.</div>
    </div>
  </div>
</template>

<script>
  import { required, email } from 'vuelidate/lib/validators'

  export default {
    name: 'Auth',
    data() {
      return {
        // email: "iushko@novotorica.com",
        // password: "Password1!",

        loginData: {
          email: '',
          password: '', // Password1!
        },
        submitted: false,

        forgotEmail: '',
        submittedForgot: false,

        currentBlock: 'Login',
      }
    },
    methods: {
      async submitLogin() {
        this.submitted = true
        this.$v.loginData.$touch()
        if (this.$v.loginData.$invalid) return false

        if (this.onSend) return false
        this.onSend = true

        let res = await this.$api.login(this.loginData)

        let alertData = {}
        if (res instanceof Error) {
          alertData.alertType = 'error'

          if (
            res.response.data.message === 'error.admin.notFound' ||
            res.response.data.message === 'error.password.incorrect'
          ) {
            alertData.alertMessage = 'email or password entered incorrectly'
          } else {
            alertData.alertMessage = 'Something went wrong'
          }

          this.$store.commit('setAlert', alertData)
          this.onSend = false
          return false
        }

        this.onSend = false
        this.$router.push({ path: '/' })
      },
      async resetPassord() {
        this.submittedForgot = true
        this.$v.forgotEmail.$touch()

        if (this.$v.forgotEmail.$invalid) return false
        if (this.onSend) return false
        this.onSend = true

        let res = await this.$api.profile.requestResetPassword({ email: this.forgotEmail })

        let alertData = {}
        if (res instanceof Error) {
          alertData.alertType = 'error'

          if (res.response.data.message === 'error.admin.notFound') {
            alertData.alertMessage = 'email not found'
          } else {
            alertData.alertMessage = 'Something went wrong'
          }

          this.$store.commit('setAlert', alertData)
          this.onSend = false
          return false
        } else if (res === true) {
          alertData.alertType = 'success'
          alertData.alertMessage = 'password reset link has been successfully sent to your email'

          this.forgotEmail = ''
          this.currentBlock = 'Forgotten password msg'
          this.$store.commit('setAlert', alertData)
          this.onSend = false
        }
      },
    },

    validations: {
      loginData: {
        email: {
          required,
          email,
        },
        password: {
          required,
        },
      },
      forgotEmail: {
        required,
        email,
      },
    },
  }
</script>

<style lang="scss" scoped src="../assets/scss/auth/auth.scss" />
