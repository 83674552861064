<template>
  <div class="page-default page-broadcast-cats">
    <v-app>
      <v-data-table
        :headers="headers"
        :items="broadcastCategories"
        item-key="name"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :footer-props="rowsPerPageItems"
        :items-per-page="10"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-card-title>Broadcast Categories</v-card-title>
            <v-divider class="mx-4" inset vertical />
            <v-spacer />

            <v-text-field
              v-model="searchText"
              class="mx-4"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              @keyup.enter="sendSearch"
            />
            <v-dialog v-model="dialog" max-width="510px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"> New Category </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field v-model="editedItem.title" label="Category Title" />
                        <v-text-field v-model="editedItem.name" label="Category Name" />
                        <div v-if="nameIsTaken" class="msg-error">This name is taken</div>
                        <br />
                        <v-checkbox v-model="setIsNew" label="Set isNew" />
                        <v-textarea
                          v-model="editedItem.description"
                          name="category-description"
                          label="Description"
                          hint="Description text..."
                        />
                        <v-row>
                          <v-col cols="2" sm="2" md="2">
                            <div
                              class="file-prevew"
                              :style="`background-image: url(${newImage ? newImage : editedItem.previewPath});`"
                            />
                          </v-col>
                          <v-col cols="10" sm="10" md="10">
                            <v-file-input v-model="fileName" show-size label="Image" @change="onFileChange" />
                          </v-col>
                        </v-row>
                        <div class="progress-wrap">
                          <v-progress-linear v-if="onSend" color="primary accent-4" indeterminate rounded height="6" />
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
                  <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="510px">
              <v-card>
                <v-card-title class="headline">
                  Are you sure you want to {{ deleteRestoreTitle }} this item?
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                  <v-btn v-if="deleteRestoreTitle === 'delete'" color="blue darken-1" text @click="deleteItemConfirm">
                    OK
                  </v-btn>
                  <v-btn v-else color="blue darken-1" text @click="restoreItemConfirm">OK</v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editItem(item, item.id)"> mdi-pencil </v-icon>
          <v-icon v-if="item.deleted === 'NOT_DELETED'" small @click="deleteItem(item)"> mdi-delete </v-icon>
          <v-icon v-else small @click="restoreItem(item)"> mdi-backup-restore </v-icon>
        </template>

        <template v-slot:[`item.title`]="{ item }">
          <div style="display: flex; align-items: center">
            <img
              :src="`${item.previewPath}`"
              style="width: 52px; height: 42px; border-radius: 5px; object-fit: cover !important; margin-right: 10px"
            />
            <div class="table-sub-title">{{ item.title }}</div>
          </div>
        </template>
        <template v-slot:[`item.deleted`]="{ item }">
          <div
            :class="[
              'status-inactive',
              {
                'status-active': item.deleted === 'NOT_DELETED',
              },
            ]"
          >
            {{ item.deleted }}
          </div>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
          <div>{{ formatDate(item.createdAt) }}</div>
          <div class="employee-table-sub-title">{{ formatDateAgo(item.createdAt) }}</div>
        </template>
        <template v-slot:[`item.description`]="{ item }">
          <div v-if="item.description">
            {{ item.description.length > 100 ? item.description.slice(0, 100) : item.description }}
          </div>
        </template>
      </v-data-table>
    </v-app>

    <br />
    <br />
    <br />
  </div>
</template>
<script>
  export default {
    name: 'StreamCategories',
    data() {
      return {
        broadcastCategories: [],
        meta: {},
        nameIsTaken: false,

        params: {
          searchLike: null,
          sort: 'id',
          order: 'ASC',
          take: 10,
          page: 1,
        },
        headers: [
          { text: 'id', value: 'id', align: 'start' },
          { text: 'title', value: 'title', sortable: false },
          { text: 'description', value: 'description', sortable: false },
          { text: 'followersCount', value: 'followersCount', sortable: false },
          { text: 'isNew', value: 'isNew', sortable: false },
          { text: 'isPopular', value: 'isPopular', sortable: false },
          { text: 'deleted', value: 'deleted', sortable: false },
          { text: 'created At', value: 'createdAt', sortable: false },
          { text: 'Actions', value: 'actions', sortable: false },
        ],

        dialog: false,
        dialogDelete: false,
        editedIndex: -1,
        editedItem: {
          title: '',
          previewPath: '',
          description: '',
          name: '',
        },
        defaultItem: {
          title: '',
          previewPath: '/img/app/load-img.svg',
          description: '',
          name: '',
        },
        setIsNew: false,
        editedCategoryId: null,
        deleteRestoreTitle: 'delete',

        totalItems: 0,
        searchText: '',
        sortBy: 'id',
        sortDesc: false,
        itemsPerPage: 10,
        options: {},
        rowsPerPageItems: { 'items-per-page-options': [10, 25, 50, 100] },
        loading: true,

        newImageFile: null,
        newImage: null,
        fileName: null,
        onSend: false,
      }
    },
    computed: {
      formTitle() {
        return this.editedIndex === -1 ? 'New Category' : 'Edit Category'
      },
    },
    watch: {
      options: {
        handler() {
          this.getData()
        },
        deep: true,
      },
      dialog(val) {
        val || this.close()
      },
      dialogDelete(val) {
        val || this.closeDelete()
      },
      'editedItem.name'(n) {
        if (n) {
          this.nameIsTaken = false
        }
      },
    },
    mounted() {
      for (let prop in this.$route.query) {
        this.params[prop] = this.$route.query[prop]
      }

      if (this.params.searchLike) {
        this.searchText = this.params.searchLike
      }
      if (this.params.page) {
        this.options.page = +this.params.page
      }
      if (this.params.take) {
        this.itemsPerPage = this.params.take
        this.options.itemsPerPage = this.params.take
      }
      if (this.params.sort) {
        this.sortBy = this.params.sort
        this.options.sortBy = this.params.sort
      }
      if (this.params.order) {
        if (this.params.order === 'DESC') {
          this.options.sortDesc = true
          this.sortDesc = true
        } else {
          this.options.sortDesc = false
          this.sortDesc = false
        }
      }
      this.getFirstData()
    },
    methods: {
      async getData() {
        if (this.onSend) return false
        this.onSend = true
        this.loading = true

        const { sortBy, sortDesc, page, itemsPerPage } = this.options

        this.params.take = itemsPerPage
        this.params.page = page
        this.params.sort = sortBy[0]

        if (sortDesc[0] === true) {
          this.params.order = 'DESC'
        } else if (sortDesc[0] === false) {
          this.params.order = 'ASC'
        } else {
          this.params.sort = 'id'
          this.params.order = 'ASC'
        }

        if (this.params.searchLike === null) {
          let query = {
            take: this.params.take,
            page: this.params.page,
            sort: this.params.sort,
            order: this.params.order,
          }
          this.$router.push({ name: 'StreamCategories', query })
        } else {
          this.$router.push({ name: 'StreamCategories', query: this.params })
        }

        let res = await this.$api.streams.getCategories(this.params)
        console.log(res)
        this.broadcastCategories = res.data
        this.totalItems = res.meta.itemCount
        this.onSend = false
        this.loading = false
      },
      async getFirstData() {
        this.onSend = true
        this.loading = true

        let res = await this.$api.streams.getCategories(this.params)
        console.log('🚀 ~ file: StreamCategories.vue ~ line 286 ~ getFirstData ~ res', res)

        this.broadcastCategories = res.data
        this.totalItems = res.meta.itemCount

        this.onSend = false
        this.loading = false
      },
      sendSearch() {
        this.options.page = 1
        if (this.params.searchLike === '') {
          this.params.searchLike = null
        } else {
          this.params.searchLike = this.searchText
        }
        this.getData()
      },
      formatStatus(status) {
        if (status === 'ACTIVE') {
          return 'Active'
        } else if (status === 'BANNED') {
          return 'Banned'
        }
        return 'Undefined'
      },
      formatDate(date) {
        return this.$dayjs(date).format('MMMM DD, YYYY')
      },
      formatDateAgo(date) {
        return this.$dayjs(date).fromNow()
      },

      editItem(item) {
        this.editedIndex = this.broadcastCategories.indexOf(item)
        this.editedCategoryId = item.id
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem(item) {
        this.editedIndex = this.broadcastCategories.indexOf(item)
        this.editedCategoryId = item.id
        this.deleteRestoreTitle = 'delete'
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      restoreItem(item) {
        this.editedIndex = this.broadcastCategories.indexOf(item)
        this.deleteRestoreTitle = 'restore'
        this.editedCategoryId = item.id
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      async deleteItemConfirm() {
        let res = await this.$api.streams.deleteStreamCategory(this.editedCategoryId)
        this.editedCategoryId = null

        var index = this.broadcastCategories.indexOf(this.editedIndex)

        if (index !== -1) {
          this.broadcastCategories[index].deleted = res.deleted
        }
        this.closeDelete()
      },
      async restoreItemConfirm() {
        let res = await this.$api.streams.restoreStreamCategory(this.editedCategoryId)
        this.editedCategoryId = null
        var index = this.broadcastCategories.indexOf(this.editedIndex)

        if (index !== -1) {
          this.broadcastCategories[index].deleted = res.deleted
        }
        this.closeDelete()
      },
      close() {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      closeDelete() {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      async save() {
        if (this.onSend) return false
        this.nameIsTaken = false
        this.onSend = true

        let category = {
          title: {
            default: this.editedItem.title,
            ru: this.editedItem.title,
            en: this.editedItem.title,
            otherLanguage: '',
          },
          name: this.editedItem.name,
          description: this.editedItem.description,
        }

        if (this.newImageFile) {
          let formData = new FormData()
          formData.append('files', this.newImageFile)
          let res = await this.$api.uploader.uploadFile(formData)

          this.editedItem.previewPath = res.data[0].url
          category.previewPath = res.data[0].filename
        }

        try {
          if (this.editedIndex > -1) {
            if (this.setIsNew) {
              category.createdAt = new Date()
            }
            await this.$api.streams.updateStreamCategory(this.editedCategoryId, category)

            this.onSend = false
            this.editedCategoryId = null
            this.newImageFile = null
            this.newImage = null
            this.fileName = null
            this.setIsNew = false

            Object.assign(this.broadcastCategories[this.editedIndex], this.editedItem)
          } else {
            await this.$api.streams.createStreamCategory(category)
            this.broadcastCategories.push(this.editedItem)
            this.onSend = false
          }
          this.close()
        } catch (e) {
          console.log('errr =>>>>>>>>>', e)
          if (e.error === 'Conflict') {
            this.nameIsTaken = true
            this.onSend = false
          }
        }
      },

      // upload files
      async onFileChange(event) {
        let file = event
        this.newImageFile = file

        let vm = this
        let reader = new FileReader()
        reader.onload = (e) => {
          vm.newImage = e.target.result
        }
        if (this.fileName) {
          reader.readAsDataURL(file)
        }
      },

      //vuetify
      toggleOrder() {
        this.sortDesc = !this.sortDesc
      },
      nextSort() {
        let index = this.headers.findIndex((h) => h.value === this.sortBy)
        index = (index + 1) % this.headers.length
        this.sortBy = this.headers[index].value
      },
    },
  }
</script>

<style lang="scss" scoped src="@/assets/scss/layout/page.scss" />
<style lang="scss">
  th.text-start {
    text-align: left;
  }
  .elevation-1 .text-start {
    font-weight: 600;
    font-size: 12px !important;
    line-height: 16px;
    color: #000000;
  }
  .v-data-table__wrapper {
    margin-top: 0;
    border-radius: 4px;
    border: 1px solid #c2c9d1;
    border-bottom: none;
  }
  .v-data-table-header {
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.5) !important;
    // padding: 26px 16px 14px;
    border-bottom: 1px solid #c2c9d1;
    border-right: 1px solid #c2c9d1;
    background-repeat: no-repeat;
    background-position: top 31px right 22px;
    cursor: pointer;
    position: relative;
  }
  .text-start a {
    color: #000000;
  }
  .sortable:before {
    content: '';
    position: absolute;
    width: 32px;
    height: 10px;
    right: 0px;
    background: url(/img/app/triangle.svg) 0 0 no-repeat;
  }
  .sortable.desc:before {
    background: url(/img/app/triangle-blue.svg) 0 0 no-repeat;
  }
  .sortable.asc:before {
    background: url(/img/app/triangle-blue.svg) 0 0 no-repeat;
    transform: rotate(180deg);
    background-position: 22px 6px;
  }
  th.text-start.sortable {
    position: relative;
  }
  .v-data-table-header__icon {
    display: none !important;
  }
  label {
    margin-top: 0px;
  }
  body .v-application .elevation-1 {
    box-shadow: none !important;
  }
  body .v-divider--vertical.v-divider--inset {
    display: none;
  }
  .file-prevew {
    height: 34px;
    width: auto;
    background-size: contain;
  }
  .progress-wrap {
    height: 6px;
  }
</style>
