<template>
  <div id="app">
    <Header v-if="showHeaderNavbar" />
    <router-view />
    <alert v-if="alertShow" />
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import Header from './components/layout/header'
  import alert from './components/elements/alert'

  export default {
    name: 'App',
    components: {
      Header,
      alert,
    },
    computed: {
      ...mapState(['showHeaderNavbar', 'alertShow']),
    },
  }
</script>

<style lang="scss" src="./assets/scss/main.scss" />
