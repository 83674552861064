var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"employee"},[(_vm.dataLoaded)?_c('div',{staticClass:"employee-table-header"},[_vm._m(0),_c('div',{staticClass:"employee-table-header-right"},[_c('div',{staticClass:"employee-table-header-search"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.params.searchLike),expression:"params.searchLike"}],attrs:{"type":"text","placeholder":"Search"},domProps:{"value":(_vm.params.searchLike)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filterOn.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.params, "searchLike", $event.target.value)}}})]),_c('div',{staticClass:"employee-table-header-create"},[_c('router-link',{attrs:{"to":"/employees/create"}},[_c('img',{attrs:{"src":"/img/app/ico-plus.svg","alt":""}}),_vm._v(" Create employee ")])],1)])]):_vm._e(),(_vm.dataLoaded)?_c('div',{staticClass:"employee-table"},[_c('div',{staticClass:"employee-table-head"},[_c('div',{class:[
          'employee-table-head-cell',
          {
            'cell-sort': _vm.params.sort === 'fullName',
            'cell-sort-desc': _vm.params.order === 'DESC' && _vm.params.sort === 'fullName',
          } ],on:{"click":function($event){return _vm.switchCurrentSort('fullName')}}},[_vm._v(" Employee ")]),_c('div',{class:[
          'employee-table-head-cell',
          {
            'cell-sort': _vm.params.sort === 'lastActivityDate',
            'cell-sort-desc': _vm.params.order === 'DESC' && _vm.params.sort === 'lastActivityDate',
          } ],on:{"click":function($event){return _vm.switchCurrentSort('lastActivityDate')}}},[_vm._v(" Last Activity ")]),_c('div',{class:[
          'employee-table-head-cell',
          {
            'cell-sort': _vm.params.sort === 'role',
            'cell-sort-desc': _vm.params.order === 'DESC' && _vm.params.sort === 'role',
          } ],on:{"click":function($event){return _vm.switchCurrentSort('role')}}},[_vm._v(" Role ")]),_c('div',{class:[
          'employee-table-head-cell',
          {
            'cell-sort': _vm.params.sort === 'status',
            'cell-sort-desc': _vm.params.order === 'DESC' && _vm.params.sort === 'status',
          } ],on:{"click":function($event){return _vm.switchCurrentSort('status')}}},[_vm._v(" Status ")])]),_c('div',{staticClass:"employee-table-body"},_vm._l((_vm.users),function(user,idx){return _c('div',{key:idx,staticClass:"employee-table-row"},[_c('div',{staticClass:"employee-table-body-cell"},[_c('router-link',{attrs:{"to":("/employees/edit/" + (user.id))}},[_c('div',[_vm._v(_vm._s(user.fullName))])]),_c('div',{staticClass:"employee-table-sub-title"},[_vm._v(_vm._s(user.email))])],1),_c('div',{staticClass:"employee-table-body-cell"},[_c('div',[_vm._v(_vm._s(_vm.formatDate(user.lastActivityDate)))]),_c('div',{staticClass:"employee-table-sub-title"},[_vm._v(_vm._s(_vm.formatDateAgo(user.lastActivityDate)))])]),_c('div',{staticClass:"employee-table-body-cell cell-one-line"},[_vm._v(_vm._s(_vm.formatRole(user.role)))]),_c('div',{class:[
            'employee-table-body-cell cell-one-line status-inactive',
            {
              'status-active': user.status === 'ACTIVE',
            } ]},[_vm._v(" "+_vm._s(_vm.formatStatus(user.status))+" ")])])}),0)]):_vm._e(),(_vm.dataLoaded)?_c('div',{staticClass:"employee-table-footer"},[_c('div',{staticClass:"employee-table-footer-left"},[_c('div',[_vm._v("Rows per page:")]),_c('div',{staticClass:"rows-count"},[_vm._v(" "+_vm._s(_vm.params.take)+" "),_c('button',{on:{"click":function($event){_vm.takeListShow = !_vm.takeListShow}}},[_c('img',{attrs:{"src":"/img/app/arrow-down.svg","alt":""}})]),(_vm.takeListShow)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function () { return (_vm.takeListShow = false); }),expression:"() => (takeListShow = false)"}],staticClass:"rows-per-page-list"},_vm._l((_vm.takeList),function(takeItem,idx){return _c('div',{key:idx,staticClass:"rows-per-page-list-item"},[_c('button',{on:{"click":function($event){return _vm.changeCountPerPage(takeItem)}}},[_vm._v(_vm._s(takeItem))])])}),0):_vm._e()])]),_c('div',{staticClass:"employee-table-footer-right"},[_c('div',{staticClass:"paggination-items-count"},[_vm._v(" "+_vm._s(_vm.startIndexPaginate + 1)+"-"+_vm._s(_vm.currentMaxCount)+" of "+_vm._s(_vm.meta.itemCount)+" items ")]),_c('button',{staticClass:"pagination-prev",on:{"click":_vm.prevPagination}},[_c('img',{attrs:{"src":"/img/app/arrow-right.svg","alt":""}})]),_c('button',{staticClass:"pagination-next",on:{"click":_vm.nextPagination}},[_c('img',{attrs:{"src":"/img/app/arrow-right.svg","alt":""}})])])]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"employee-table-header-left"},[_c('h1',[_vm._v("Employees")])])}]

export { render, staticRenderFns }