import { getRequest, postRequest, putRequest, deleteRequest } from './requests'

const routePathBroadcast = 'stream/admin/broadcasts'
const routePathStream = 'stream/admin/streams'
const routePathStreamCats = 'stream/admin/broadcastCategories'

export default {
  async getAllBroadcasts(params) {
    return await getRequest(`${routePathBroadcast}`, { params })
  },
  async getBroadcastById(id) {
    return await getRequest(`${routePathBroadcast}/${id}`)
  },
  async getById(id) {
    return await getRequest(`${routePathStream}/${id}`)
  },

  async getAllStreams(params) {
    return await getRequest(`${routePathStream}`, { params })
  },

  async createStream(data) {
    return await postRequest(`${routePathStream}`, data)
  },

  async createVod(data) {
    return await postRequest('vodUpload', data)
  },

  async createBroadcast(data) {
    return await postRequest(`${routePathBroadcast}`, data)
  },

  async updateBroadcast(id, data) {
    return await putRequest(`${routePathBroadcast}/${id}`, data)
  },
  async deleteBroadcast(id, data) {
    return await deleteRequest(`${routePathBroadcast}/${id}`, data)
  },
  async banBroadcast(id, data) {
    return await putRequest(`${routePathBroadcast}/${id}/ban`, data)
  },
  async approveBroadcast(id) {
    return await putRequest(`${routePathBroadcast}/${id}/approve`)
  },

  async getCategories(params) {
    return await getRequest(`stream/broadcastCategories`, { params })
  },
  async createStreamCategory(data) {
    return await postRequest(`${routePathStreamCats}`, data)
  },
  // async getStreamCategory(id) {
  //     return await getRequest(`${routePathStreamCats}/${id}`)
  // },
  async updateStreamCategory(id, data) {
    return await putRequest(`${routePathStreamCats}/${id}`, data)
  },
  async deleteStreamCategory(id) {
    return await deleteRequest(`${routePathStreamCats}/${id}`)
  },
  async restoreStreamCategory(id) {
    return await putRequest(`${routePathStreamCats}/${id}/restore`)
  },
  async getReports(params) {
    return await getRequest('report/admin/broadcastReports', { params })
  },
}
