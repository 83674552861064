<template>
  <div v-if="dataLoaded" class="home-categories">
    <h2 class="title">
      <span>Categories</span>
      <router-link to="/categories/" class="see-all">See all</router-link>
    </h2>

    <div class="category-list">
      <div v-for="(categoryItem, idx) in categoriesList" :key="idx" class="category-item">
        <!-- <router-link :to="'/category/' + categoryItem.name.toLowerCase()" @click.native="test($event)"> -->
        <div
          class="category-card-wrap"
          @mousedown.left="mouseDownBlur($event, categoryItem)"
          @mouseup="mouseUpBlur($event, categoryItem)"
        >
          <div class="category-top-block" :style="`background-image: url(${categoryItem.previewPath})`">
            <div
              :class="[
                'category-top-block-flag',
                {
                  'category-top-block-flag-new': categoryItem.flag === 'New!',
                  'category-top-block-flag-popular': categoryItem.flag === 'Popular',
                },
              ]"
            >
              {{ categoryItem.flag }}
            </div>
          </div>

          <div class="category-bot-block">
            <div class="category-bot-left-block">
              <!-- <router-link :to="'/category/' + categoryItem.name.toLowerCase()"> -->
              <div class="video-title">{{ categoryItem.title.default }}</div>
              <!-- </router-link> -->
              <div class="video-description">{{ categoryItem.description }}</div>
            </div>
            <div class="category-bot-right-block">
              <button class="like-block">
                <svg :class="['like-block-icon', { 'like-block-icon-active': categoryItem.isFollow }]">
                  <use class="like-block-icon-svg" xlink:href="/img/app/video/like-icon.svg#like-icon" />
                </svg>
              </button>
              <img class="icon-arrow-category" src="/img/app/layout/long-arrow-right.svg" alt="" />
            </div>
          </div>
          <div class="category-card-wrap-over" />
        </div>
        <!-- </router-link> -->
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'Categories',
    components: {},
    data() {
      return {
        categories: [
          {
            id: 1,
            name: 'Yoga',
            description: 'The best morning workout you can do to wake up your body.',
            flag: '',
            imgUrl: '/img/categories/category-tmb-1.jpg',
            liked: true,
          },
          {
            id: 2,
            name: 'Stretching',
            description: 'The best morning workout you can do to wake up your body',
            flag: 'Popular',
            imgUrl: '/img/categories/category-tmb-2.jpg',
            liked: false,
          },
          {
            id: 3,
            name: 'Fitness',
            description: 'The best morning workout you can do to wake up your body',
            flag: 'Popular',
            imgUrl: '/img/categories/category-tmb-3.jpg',
            liked: true,
          },
          {
            id: 4,
            name: 'Meditation',
            description: 'The best morning workout',
            flag: '',
            imgUrl: '/img/categories/category-tmb-4.jpg',
            liked: false,
          },
          {
            id: 5,
            name: 'Workshop',
            description: 'The best morning workout you can do to wake up your body',
            flag: '',
            imgUrl: '/img/categories/category-tmb-5.jpg',
            liked: false,
          },
          {
            id: 6,
            name: 'Easy',
            description: 'The best morning workout you can do to wake up your body',
            flag: null,
            imgUrl: '/img/categories/category-tmb-6.jpg',
            liked: true,
          },
          {
            id: 7,
            name: 'Stretching professional',
            description: 'The best morning workout you can do to wake up your body',
            flag: '',
            imgUrl: '/img/categories/category-tmb-7.jpg',
            liked: false,
          },
          {
            id: 8,
            name: 'Meditation',
            description: 'The best morning workout you can do to wake up your body',
            flag: '',
            imgUrl: '/img/categories/category-tmb-8.jpg',
            liked: true,
          },
          {
            id: 9,
            name: 'Workshop',
            description: 'The best morning workout you can do to wake up your body',
            flag: '',
            imgUrl: '/img/categories/category-tmb-1.jpg',
            liked: true,
          },
          {
            id: 10,
            name: 'Stretching professional',
            description: 'The best morning workout you can do to wake up your body',
            flag: 'Popular',
            imgUrl: '/img/categories/category-tmb-2.jpg',
            liked: true,
          },
        ],
        newCategories: [],

        dataLoaded: false,
      }
    },
    computed: {
      ...mapState(['isAuthorized']),
      categoriesList() {
        // return this.categories.slice(0, 6)
        return this.newCategories.slice(0, 6)
      },
    },
    async mounted() {
      let res
      if (this.isAuthorized) {
        res = await this.$api.broadcasts.getCategoriesPrivate()
      } else {
        res = await this.$api.broadcasts.getCategories()
      }

      this.newCategories = res.data
      this.dataLoaded = true
      this.newCategories.forEach((cat) => console.log(cat.rate))
      console.log('🚀 ~ file: categories.vue ~ line 142 ~ mounted ~ this.newCategories', this.newCategories)
    },
    methods: {
      mouseDownBlur(event, categoryItem) {
        console.log('🚀 ~ file: categories.vue ~ line 149 ~ mouseDownBlur ~ event', event)
        event.stopPropagation()
        if (event.target.getAttribute('class') === 'like-block-icon-svg') {
          categoryItem.liked = !categoryItem.liked
        } else {
          event.currentTarget.classList.add('cat-item-blur-wrap')
          this.$router.push({ path: `/category/${categoryItem.name.toLowerCase()}` })
        }
      },
      mouseUpBlur(event) {
        event.currentTarget.classList.remove('cat-item-blur-wrap')
      },
      followCategory() {
        let res = this.$api.broadcast.followCategory()
        console.log('🚀 ~ file: categories.vue ~ line 178 ~ followCategory ~ res', res)
      },
      unfollowCategory() {
        let res = this.$api.broadcast.unfollowCategory()
        console.log('🚀 ~ file: categories.vue ~ line 178 ~ followCategory ~ res', res)
      },
    },
  }
</script>
