<template>
  <div class="legal-doc">
    <div style="display: flex; align-items: center; margin-bottom: 12px">
      <label for="title" style="margin-top: 10px; margin-right: 10px">Title</label>
      <input id="title" v-model="title" type="text" />
    </div>

    <div class="textareas">
      <textarea id="textarea" v-model="docText" />
      <div class="doc-preview">
        <div v-if="docText" id="preview" v-html="$marked(docText)" />
        <br />
        <div style="font-weight: bold">This document was last updated on {{ formatDate(lastUpdate) }}</div>
      </div>
    </div>

    <button class="btn-primary" @click="saveChanges()">Save</button>
  </div>
</template>
<script>
  export default {
    name: 'LegalDoc',
    data() {
      return {
        docText: ` `,
        lastUpdate: '',
        title: '',
        oldTitle: '',
      }
    },
    async created() {
      await this.getDoc()
    },
    mounted() {
      const textarea = document.getElementById('textarea')
      const docPreview = document.getElementById('preview')
      console.log(docPreview)
      textarea.addEventListener('scroll', () => {
        docPreview.scrollTop = textarea.scrollTop
      })
    },

    methods: {
      async saveChanges() {
        console.log(this.title, this.oldTitle)

        if (this.title != this.oldTitle) {
          await this.$api.legal.deleteLegalDoc(this.oldTitle.toLowerCase().replace(/ /g, '_'))
          await this.$api.legal.changeLegalDoc(this.title.toLowerCase().replace(/ /g, '_'), {
            value: this.docText,
          })
          return
        }
        await this.$api.legal.changeLegalDoc(this.$route.params.doc_type, {
          value: this.docText,
        })
      },
      async getDoc() {
        const res = await this.$api.legal.getLegalDoc(this.$route.params.doc_type)
        this.oldTitle = this.formatTitle(this.$route.params.doc_type)
        this.title = this.formatTitle(this.$route.params.doc_type)
        this.lastUpdate = res.date
        this.docText = res.value
      },
      formatDate(date) {
        return this.$dayjs(date).format('MMMM DD, YYYY')
      },
      formatTitle(title) {
        return (title.charAt(0).toUpperCase() + title.slice(1)).replace(/_/g, ' ')
      },
    },
  }
</script>

<style lang="scss" scoped>
  .legal-doc {
    padding: 24px;
    input {
      background-color: #fff;
    }
    textarea,
    .doc-preview {
      padding: 12px;
      background-color: white;
      width: 50%;
    }
    #preview {
      height: calc(100vh - 219px);
      overflow-y: scroll;
    }
    textarea {
      margin-right: 12px;
    }
    .textareas {
      display: flex;
      margin-bottom: 24px;
    }
  }
</style>
