var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-default page-broadcast-cats"},[_c('v-app',[_c('v-data-table',{key:_vm.dataTableKey,staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.videos,"item-key":"id","options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"footer-props":_vm.rowsPerPageItems,"items-per-page":10},on:{"update:options":function($event){_vm.options=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.approveItem(item)}}},[_vm._v(" mdi-check ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.declineItem(item)}}},[_vm._v(" mdi-close ")]),(_vm.isItemHasVod(item))?_c('v-icon',{on:{"click":function($event){return _vm.openVodPlayer(item)}}},[_vm._v(" mdi-play ")]):_vm._e()],1)]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('img',{staticStyle:{"width":"42px","height":"32px","border-radius":"5px","object-fit":"cover","margin-right":"10px"},attrs:{"src":("" + (item.previewPath))}}),_c('div',{staticClass:"table-sub-title table-sub-title--short"},[_vm._v(" "+_vm._s(item.name)+" ")])])]}},{key:"item.author",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-sub-title",staticStyle:{"margin":"8px 0 0 6px","float":"left"}},[_vm._v(" "+_vm._s(item.channelIds ? _vm.setChannel(item.channelIds[0]) : '')+" ")])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.formatDate(item.createdAt)))]),_c('div',{staticClass:"employee-table-sub-title"},[_vm._v(" "+_vm._s(_vm.formatDateAgo(item.createdAt))+" ")])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.description.length > 100 ? item.description.slice(0, 100) : item.description)+" ")])]}},{key:"item.categories",fn:function(ref){
var item = ref.item;
return [_c('div',_vm._l((item.categories),function(cat,idx){return _c('span',{key:idx,staticClass:"test-111"},[_vm._v(" "+_vm._s(cat.title)+_vm._s(idx !== item.categories.length - 1 ? ',' : '')+" ")])}),0)]}}],null,true)})],1),_c('br'),_c('br'),_c('br'),_c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.dialogs.decline),callback:function ($$v) {_vm.$set(_vm.dialogs, "decline", $$v)},expression:"dialogs.decline"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline mt-0"},[_vm._v("Decline broadcast")]),_c('v-card-text',[_c('v-container',[_c('v-textarea',{attrs:{"label":"Ban reason"},model:{value:(_vm.banReason),callback:function ($$v) {_vm.banReason=$$v},expression:"banReason"}}),(!_vm.$v.banReason.required && _vm.$v.banReason.$error)?_c('div',{staticClass:"msg-error"},[_c('img',{staticClass:"error-icon",attrs:{"src":"/img/app/error.svg","alt":""}}),_vm._v(" Ban reason is required ")]):_vm._e()],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.cancelDeclineStream}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.sendBanReq}},[_vm._v(" Send ")])],1)],1)],1),_c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.dialogs.approve),callback:function ($$v) {_vm.$set(_vm.dialogs, "approve", $$v)},expression:"dialogs.approve"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline mt-0"},[_vm._v("Approve broadcast")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.cancelApproveStream}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.sendApproveReq}},[_vm._v(" Approve ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }