import { getRequest, deleteRequest, putRequest } from './requests'

const routePath = 'user/admin/users'

export default {
  async get(id) {
    return await getRequest(`${routePath}/${id}`)
  },
  async delete(id) {
    return await deleteRequest(`${routePath}/${id}`)
  },
  async getAll(params) {
    return await getRequest(`${routePath}`, { params })
  },
  async getMap(ids) {
    return await getRequest(`${routePath}/map`, { params: { ids } })
  },
  async ban(id, data) {
    return await putRequest(`${routePath}/${id}/ban`, data)
  },
  async unban(id, data) {
    return await deleteRequest(`${routePath}/${id}/ban`, data)
  },
  async restore(id) {
    return await putRequest(`${routePath}/${id}/restore`)
  },
}
