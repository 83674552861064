<template>
  <div class="legal employee channels-wrap">
    <v-app>
      <v-card-title>
        Legal
        <v-spacer />
      </v-card-title>
      <v-data-table :headers="headers" :items="legals" :hide-default-footer="true" item-key="id">
        <template v-slot:[`item.title`]="{ item }">
          {{ formatTitle(item.title) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <router-link :to="`/legal/${item.title}`">
            <v-icon small class="mr-2"> mdi-pencil </v-icon>
          </router-link>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </v-app>
  </div>
</template>
<script>
  export default {
    name: 'Legal',
    data() {
      return {
        legals: [],

        headers: [
          { text: 'title', value: 'title' },
          { text: 'Actions', value: 'actions', sortable: false, width: '5%' },
        ],
      }
    },

    async created() {
      const res = await this.$api.legal.getAll()
      this.legals = res.map((el) => {
        return { title: el }
      })
    },
    methods: {
      async deleteItem(item) {
        await this.$api.legal.deleteLegalDoc(item.title)
        const index = this.legals.indexOf(item)
        this.legals.splice(index, 1)
      },
      formatTitle(title) {
        return (title.charAt(0).toUpperCase() + title.slice(1)).replace(/_/g, ' ')
      },
    },
  }
</script>

<!--<style lang="scss" scoped src="../assets/scss/users/users.scss" />-->

<style lang="scss">
  @import url('../assets/scss/users/users.scss');
  .legal {
    .sortable {
      background: white !important;
    }
    .v-data-table > .v-data-table__wrapper > table {
      border-bottom: 1px solid #e0e0e0;
    }
    body .employee-table-head {
      grid-template-columns: 5% 10% 10% 70% 5% !important;
    }
    body .channels-wrap .employee-table-row {
      grid-template-columns: 5% 10% 10% 70% 5%;
    }
  }
</style>
