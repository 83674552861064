import { render, staticRenderFns } from "./setPassword.vue?vue&type=template&id=41428402&scoped=true&"
import script from "./setPassword.vue?vue&type=script&lang=js&"
export * from "./setPassword.vue?vue&type=script&lang=js&"
import style0 from "../../assets/scss/auth/auth.scss?vue&type=style&index=0&id=41428402&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41428402",
  null
  
)

export default component.exports