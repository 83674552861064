<template>
  <div class="header-wrap">
    <div class="header-menu-wrap">
      <router-link v-for="(menuItem, key) in menuItems" :key="key" :to="menuItem.path">
        {{ menuItem.name }}
      </router-link>
    </div>
    <div class="user-block">
      <div class="user-avatar" @click="showUserMenu = !showUserMenu">
        {{ avatarDefault }}
      </div>
      <div v-if="showUserMenu" v-click-outside="() => (showUserMenu = false)" class="user-menu-dropdown-wrap">
        <div class="user-menu-dropdown">
          <div class="user-menu-username">
            <div class="user-avatar">{{ avatarDefault }}</div>
            <div class="user-menu-name-wrap">
              <div class="user-menu-name">{{ userName }}</div>
              <div class="user-menu-email">{{ userEmail }}</div>
            </div>
          </div>
          <div class="user-menu-list">
            <router-link to="/profile" class="user-menu-item" @click.native="showUserMenu = false">
              <img src="/img/app/user.svg" alt="" />
              Profile
            </router-link>
            <router-link to="/profile" class="user-menu-item" @click.native="showUserMenu = false">
              <img src="/img/app/settings.svg" alt="" />
              Settings
            </router-link>
          </div>
          <button class="user-menu-logout" @click="logout">
            <img src="/img/app/logout.svg" alt="" />
            Logout
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Header',
    data() {
      return {
        menuItems: [],
        showUserMenu: false,
      }
    },
    computed: {
      avatarDefault() {
        if (localStorage.getItem('fullName').length > 1) {
          const fullNameArr = localStorage.getItem('fullName').split(' ')
          if (fullNameArr.length > 1) {
            return fullNameArr[0][0] + fullNameArr[1][0]
          } else {
            return fullNameArr[0][0]
          }
        } else {
          return 'AA'
        }
      },
      userName() {
        return localStorage.getItem('fullName')
      },
      userEmail() {
        return localStorage.getItem('email')
      },
    },
    created() {
      const menuList = [
        { name: 'Dashboard', path: '/', access: 'all' },
        { name: 'Users', path: '/users', access: 'all' },
        { name: 'Employee', path: '/employees', access: 'ADMIN' },
        { name: 'Channels', path: '/channels', access: 'all' },
        { name: 'Reports', path: '/reports', access: 'all' },
        { name: 'VoD', path: '/vod', access: 'all' },
        { name: 'Broadcasts', path: '/broadcasts', access: 'all' },
        { name: 'Streams', path: '/streams', access: 'all' },
        { name: 'StreamCats', path: '/stream-categories', access: 'all' },
        { name: 'Legal', path: '/legal', access: 'all' },
        { name: 'Moderation', path: '/moderation', access: 'all' },
        { name: 'Complaints', path: '/complaints', access: 'all' },
      ]
      if (this.$store.state.admin.role === 'ADMIN') {
        this.menuItems = menuList
      } else {
        this.menuItems = menuList.filter((item) => item.access !== 'ADMIN')
      }
    },
    methods: {
      logout() {
        this.showUserMenu = false
        this.$api.logout()
        this.$router.push({ path: '/auth' })
      },
    },
  }
</script>

<style lang="scss" src="../../assets/scss/layout/header.scss" />
