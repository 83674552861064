import { getRequest, putRequest, deleteRequest } from './requests'

const routePath = 'channel/admin/channels'

export default {
  async getAll(params) {
    return await getRequest(`${routePath}`, { params })
  },
  async get(id) {
    return await getRequest(`${routePath}/${id}`)
  },
  async ban(id, data) {
    return await putRequest(`${routePath}/${id}/ban`, data)
  },
  async unban(id, data) {
    return await deleteRequest(`${routePath}/${id}/ban`, data)
  },
}
