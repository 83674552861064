<template>
  <section class="moderation-player" data-app>
    <h1>{{ vod.name }}</h1>
    <video-player v-if="vodUrl" :video-url="vodUrl" class="video-player" />

    <div style="margin-top: 1em">
      <v-btn class="ban-btn" @click="declineItem"> Ban </v-btn>
      <v-btn class="approve-btn" @click="approveItem"> Approve </v-btn>
    </div>

    <v-dialog v-model="dialogs.ban" width="600">
      <v-card>
        <v-card-title class="headline mt-0">Decline broadcast</v-card-title>

        <v-card-text>
          <v-container>
            <v-textarea v-model="banReason" label="Ban reason" />
            <div v-if="!$v.banReason.required && $v.banReason.$error" class="msg-error">
              <img src="/img/app/error.svg" alt="" class="error-icon" />
              Ban reason is required
            </div>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="cancelDeclineStream"> Cancel </v-btn>
          <v-btn color="blue darken-1" text @click="sendBanReq"> Send </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogs.approve" width="600">
      <v-card>
        <v-card-title class="headline mt-0">Approve broadcast</v-card-title>

        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="cancelApproveStream"> Cancel </v-btn>
          <v-btn color="blue darken-1" text @click="sendApproveReq"> Approve </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
  import { required } from 'vuelidate/lib/validators'

  import VideoPlayer from '../components/elements/VideoPlayer.vue'

  export default {
    components: { VideoPlayer },
    validations: {
      banReason: {
        required,
      },
    },
    data() {
      return {
        streamId: null,
        broadcastId: null,
        vodUrl: '',
        vod: {},
        banReason: '',
        dialogs: {
          approve: false,
          ban: false,
        },
      }
    },
    async created() {
      this.streamId = this.$route.params.streamId
      this.broadcastId = this.$route.params.broadcastId

      const vod = await this.getVodByStreamId(this.streamId)
      this.vod = vod
      this.vodUrl = vod?.vodUrl
    },
    methods: {
      async getVodByStreamId(id) {
        const vod = await this.$api.streams.getById(id)
        return vod
      },
      approveItem() {
        this.dialogs.approve = true
      },
      async declineItem() {
        this.dialogs.ban = true
      },
      async sendBanReq() {
        this.$v.$touch()

        if (this.$v.$invalid) {
          return false
        }

        const banRes = await this.$api.streams.banBroadcast(this.broadcastId, {
          banReason: this.banReason,
          banDate: new Date().toISOString(),
        })

        if (banRes) {
          this.dialogs.ban = false
          this.$router.push({ name: 'Moderation' })
        }
      },
      async sendApproveReq() {
        const approveRes = await this.$api.streams.approveBroadcast(this.broadcastId)

        if (approveRes) {
          this.dialogs.approve = false

          this.$router.push({ name: 'Moderation' })
        }
      },
      cancelDeclineStream() {
        this.dialogs.ban = false
      },
      cancelApproveStream() {
        this.dialogs.approve = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .moderation-player {
    max-width: 820px;
    width: 100%;
    margin: 0 auto;
    margin-top: 2em;

    h1 {
      margin-bottom: 1em;
    }
    .ban-btn {
      background: #f44336 !important;
      color: white;
      margin-right: 10px;
    }
    .approve-btn {
      background: #2196f3 !important;
      color: white;
    }
    .video-player {
      width: 820px;
      height: 461.25px;
    }
  }
</style>
