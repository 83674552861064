<template>
  <div class="create-employee-wrap profile-page-wrap">
    <div v-if="showModal" class="modal-wrap">
      <div v-click-outside="() => (showModal = false)" class="modal">
        <div class="modal-header">
          <div class="modal-title-wrap">
            <div class="attention-wrap" />
            <div class="modal-title">
              {{ admin.fullName }}
            </div>
          </div>
          <img src="/img/app/close.svg" alt="" class="close-modal" @click="showModal = false" />
        </div>
        <div class="modal-content">
          <div class="form-group">
            <label for="old-password">Old Password</label>
            <input
              id="old-password"
              v-model="dataPass.oldPassword"
              type="password"
              :class="{ error: submitted && $v.dataPass.oldPassword.$error }"
            />

            <div v-if="submitted && $v.dataPass.oldPassword.$error">
              <div v-if="!$v.dataPass.oldPassword.required" class="msg-error">Old Password is required</div>
            </div>
          </div>

          <div class="form-group">
            <label for="new-password">New Password</label>
            <input
              id="new-password"
              v-model="dataPass.newPassword"
              type="password"
              :class="{ error: submitted && $v.dataPass.newPassword.$error }"
            />

            <div v-if="submitted && $v.dataPass.newPassword.$error">
              <div v-if="!$v.dataPass.newPassword.required" class="msg-error">Password is required</div>
              <div v-if="submitted && !$v.dataPass.newPassword.minLength" class="msg-error">
                Password must be minimum 8 characters
              </div>
              <div v-if="submitted && !$v.dataPass.newPassword.maxLength" class="msg-error">
                Password must be maximum 50 characters
              </div>
              <div v-if="submitted && !$v.dataPass.newPassword.password" class="msg-error">
                Password must contain only letters, numbers and special characters !#$%&*@^_-
              </div>
            </div>
          </div>

          <div class="form-group">
            <label for="new-password-confirm">New Password</label>
            <input
              id="new-password-confirm"
              v-model="dataPass.newPasswordConfirm"
              type="password"
              :class="{ error: submitted && $v.dataPass.newPassword.$error }"
            />

            <div v-if="submitted && $v.dataPass.newPasswordConfirm.$error">
              <div v-if="!$v.dataPass.newPassword.required" class="msg-error">Confirm Password is required</div>
              <div
                v-if="dataPass.newPasswordConfirm && !$v.dataPass.newPasswordConfirm.sameAsPassword"
                class="msg-error"
              >
                Password and Confirm Password should match
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="cancel-btn" @click="showModal = false">Cancel</button>
          <button class="btn-primary" @click="updatePassword">Save</button>
        </div>
      </div>
    </div>

    <div v-if="dataLoaded" class="create-employee">
      <h1 class="page-title">Profile</h1>
      <div class="form-group">
        <label for="full-name">Full name</label>
        <input id="full-name" v-model="admin.fullName" type="text" disabled />
      </div>

      <div class="form-group">
        <label for="email">Email</label>
        <input id="email" v-model="admin.email" type="text" disabled />
      </div>

      <div class="form-group">
        <label for="change-password">Password</label>
        <button class="change-password" @click="showModal = true">Change password</button>
      </div>
    </div>
  </div>
</template>

<script>
  import { required, minLength, maxLength, sameAs } from 'vuelidate/lib/validators'

  export default {
    name: 'Profile',
    data() {
      return {
        admin: {},

        dataLoaded: false,
        onSend: false,

        dataPass: {
          oldPassword: '', // Password1!
          newPassword: '',
          newPasswordConfirm: '',
        },

        showModal: false,
        submitted: false,
      }
    },
    async created() {
      const res = await this.$api.employees.profile()
      this.admin = res
      this.dataLoaded = true
    },
    methods: {
      async updatePassword() {
        if (this.onSend) return false
        this.onSend = true

        this.submitted = true
        this.$v.$touch()
        if (this.$v.$invalid) {
          this.onSend = false
          return false
        }

        let res = await this.$api.profile.updatePassword({
          oldPassword: this.dataPass.oldPassword,
          newPassword: this.dataPass.newPassword,
        })

        let alertData = {}
        if (res instanceof Error) {
          alertData.alertType = 'error'

          if (res.response.data.error === 'Bad Request') {
            alertData.alertMessage = res.response.data.message[0]
          } else if (res.response.data.message === 'error.password.incorrect') {
            alertData.alertMessage = 'old password is incorrect'
          } else {
            alertData.alertMessage = 'Something went wrong'
          }

          this.$store.commit('setAlert', alertData)
        } else if (res === true) {
          alertData.alertType = 'success'
          alertData.alertMessage = 'Password updated successfully'

          this.showModal = false
          this.oldPassword = ''
          this.newPassword = ''
          this.newPasswordConfirm = ''

          this.$store.commit('setAlert', alertData)
        }
        this.onSend = false
      },
    },
    validations: {
      dataPass: {
        newPassword: {
          required,
          minLength: minLength(8),
          maxLength: maxLength(50),
          password(value) {
            value
            return /^[\w!#$%&*@^-]*$/.test(value)
          },
        },
        newPasswordConfirm: { required, sameAsPassword: sameAs('newPassword') },
        oldPassword: { required },
      },
    },
  }
</script>

<style lang="scss" src="../assets/scss/profile/profile.scss" />
