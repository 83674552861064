<template>
  <div class="employee">
    <div v-if="dataLoaded" class="employee-table-header">
      <div class="employee-table-header-left">
        <h1>Employees</h1>
      </div>
      <div class="employee-table-header-right">
        <div class="employee-table-header-search">
          <input v-model="params.searchLike" type="text" placeholder="Search" @keyup.enter="filterOn" />
        </div>
        <div class="employee-table-header-create">
          <router-link to="/employees/create">
            <img src="/img/app/ico-plus.svg" alt="" />
            Create employee
          </router-link>
        </div>
      </div>
    </div>
    <div v-if="dataLoaded" class="employee-table">
      <div class="employee-table-head">
        <div
          :class="[
            'employee-table-head-cell',
            {
              'cell-sort': params.sort === 'fullName',
              'cell-sort-desc': params.order === 'DESC' && params.sort === 'fullName',
            },
          ]"
          @click="switchCurrentSort('fullName')"
        >
          Employee
        </div>
        <div
          :class="[
            'employee-table-head-cell',
            {
              'cell-sort': params.sort === 'lastActivityDate',
              'cell-sort-desc': params.order === 'DESC' && params.sort === 'lastActivityDate',
            },
          ]"
          @click="switchCurrentSort('lastActivityDate')"
        >
          Last Activity
        </div>
        <div
          :class="[
            'employee-table-head-cell',
            {
              'cell-sort': params.sort === 'role',
              'cell-sort-desc': params.order === 'DESC' && params.sort === 'role',
            },
          ]"
          @click="switchCurrentSort('role')"
        >
          Role
        </div>
        <div
          :class="[
            'employee-table-head-cell',
            {
              'cell-sort': params.sort === 'status',
              'cell-sort-desc': params.order === 'DESC' && params.sort === 'status',
            },
          ]"
          @click="switchCurrentSort('status')"
        >
          Status
        </div>
      </div>

      <div class="employee-table-body">
        <div v-for="(user, idx) in users" :key="idx" class="employee-table-row">
          <div class="employee-table-body-cell">
            <router-link :to="`/employees/edit/${user.id}`">
              <div>{{ user.fullName }}</div>
            </router-link>
            <div class="employee-table-sub-title">{{ user.email }}</div>
          </div>
          <div class="employee-table-body-cell">
            <div>{{ formatDate(user.lastActivityDate) }}</div>
            <div class="employee-table-sub-title">{{ formatDateAgo(user.lastActivityDate) }}</div>
          </div>
          <div class="employee-table-body-cell cell-one-line">{{ formatRole(user.role) }}</div>
          <div
            :class="[
              'employee-table-body-cell cell-one-line status-inactive',
              {
                'status-active': user.status === 'ACTIVE',
              },
            ]"
          >
            {{ formatStatus(user.status) }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="dataLoaded" class="employee-table-footer">
      <div class="employee-table-footer-left">
        <div>Rows per page:</div>
        <div class="rows-count">
          {{ params.take }}
          <button @click="takeListShow = !takeListShow">
            <img src="/img/app/arrow-down.svg" alt="" />
          </button>
          <div v-if="takeListShow" v-click-outside="() => (takeListShow = false)" class="rows-per-page-list">
            <div v-for="(takeItem, idx) in takeList" :key="idx" class="rows-per-page-list-item">
              <button @click="changeCountPerPage(takeItem)">{{ takeItem }}</button>
            </div>
          </div>
        </div>
      </div>
      <div class="employee-table-footer-right">
        <div class="paggination-items-count">
          {{ startIndexPaginate + 1 }}-{{ currentMaxCount }} of {{ meta.itemCount }} items
        </div>
        <button class="pagination-prev" @click="prevPagination"><img src="/img/app/arrow-right.svg" alt="" /></button>
        <button class="pagination-next" @click="nextPagination"><img src="/img/app/arrow-right.svg" alt="" /></button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Employees',
    data() {
      return {
        users: [],
        meta: {},

        takeList: [10, 25, 50, 100],
        takeListShow: false,

        dataLoaded: false,
        onSend: false,

        params: {
          searchLike: null,
          sort: 'id',
          order: 'ASC',
          take: 10,
          page: 1,
        },
      }
    },
    computed: {
      currentMaxCount() {
        if (this.meta.itemCount < this.meta.take) {
          return this.meta.itemCount
        }
        if (this.meta.take * this.meta.page > this.meta.itemCount) {
          return this.meta.itemCount
        }
        return this.meta.take * this.meta.page
      },
      startIndexPaginate() {
        if (this.meta.itemCount === 0) {
          return -1
        } else {
          return (this.meta.page - 1) * this.params.take
        }
      },
    },
    async created() {
      let res = await this.$api.employees.getAll(this.$route.query)
      this.users = res.data
      this.meta = res.meta // itemCount, page, pageCount, take

      for (let prop in this.$route.query) {
        this.params[prop] = this.$route.query[prop]
      }
      this.params.take = res.meta.take

      this.dataLoaded = true
    },
    methods: {
      async send() {
        if (this.onSend) return false
        this.onSend = true

        let res = await this.$api.employees.getAll(this.params)
        this.$router.push({ name: 'Employees', query: this.params })

        this.users = res.data
        this.meta = res.meta

        this.onSend = false
      },
      filterOn() {
        this.params.page = 1
        if (this.params.searchLike === '') {
          this.params.searchLike = null
        }
        this.send()
      },
      switchCurrentSort(sortName) {
        this.params.page = 1
        if (!this.params.sort || this.params.sort !== sortName) {
          ;(this.params.sort = sortName), (this.params.order = 'ASC')
        } else if (this.params.order === 'ASC') {
          this.params.order = 'DESC'
        } else {
          this.params.sort = 'id'
          this.params.order = 'ASC'
        }
        this.send()
      },
      changeCountPerPage(takeItem) {
        this.params.page = 1
        this.params.take = takeItem
        this.takeListShow = false
        this.send()
      },
      prevPagination() {
        if (this.meta.page === 1) {
          return
        }
        this.params.page = this.meta.page - 1
        this.send()
      },
      nextPagination() {
        if (this.startIndexPaginate + this.meta.take !== this.currentMaxCount) {
          return false
        }
        this.params.page = this.meta.page + 1
        this.send()
      },
      formatRole(role) {
        if (role === 'ADMIN') {
          return 'Admininistrator'
        } else if (role === 'MODERATOR') {
          return 'Moderator'
        }
        return 'Undefined'
      },
      formatStatus(status) {
        if (status === 'ACTIVE') {
          return 'Active'
        } else if (status === 'BANNED') {
          return 'Banned'
        }
        return 'Undefined'
      },
      formatDate(date) {
        return this.$dayjs(date).format('MMMM DD, YYYY')
      },
      formatDateAgo(date) {
        return this.$dayjs(date).fromNow()
      },
    },
  }
</script>

<style lang="scss" scoped src="../assets/scss/employee/employee.scss" />
