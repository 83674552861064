<template>
  <div class="content-wrap edit-channel">
    <div v-if="showModal && channel.status === 'ACTIVE'" class="modal-wrap">
      <div v-click-outside="() => (showModal = false)" class="modal">
        <div class="modal-header">
          <div class="modal-title">
            <div class="modale-title-error">
              <img src="/img/app/error.svg" alt="" />
            </div>
            {{ channel.name }}
          </div>
          <img src="/img/app/close.svg" alt="" class="close-modal" @click="showModal = false" />
        </div>
        <div class="modal-content">
          <div class="radio-btn-list">
            <div
              v-for="(reasonsBanItem, idx) in reasonsBanList"
              :key="idx"
              class="radio-btn-item"
              @click="curentResonBan = reasonsBanItem"
            >
              <div :class="['input-select', { 'input-select-selected': curentResonBan === reasonsBanItem }]">
                <div class="input-select-circle" />
              </div>
              {{ reasonsBanItem }}
            </div>
          </div>
          <textarea v-model="banReasonText" class="text-area" placeholder="Type your message..." />
        </div>
        <div class="modal-footer">
          <button class="cancel-btn" @click="showModal = false">Cancel</button>
          <button class="btn-danger" @click="banUser">Yes, banned</button>
        </div>
      </div>
    </div>

    <div v-if="showModal && channel.status === 'BANNED'" class="modal-wrap unblock-modal-wrap">
      <div v-click-outside="() => (showModal = false)" class="modal">
        <div class="modal-header">
          <img src="/img/app/close.svg" alt="" class="close-modal" @click="showModal = false" />
        </div>
        <div class="modal-content">
          <div class="attention-wrap" />
          <div class="unblock-title">Unblock {{ channel.name }}</div>
        </div>
        <div class="modal-footer">
          <button class="btn-primary" @click="unbanUser">Yes, unblock</button>
        </div>
      </div>
    </div>

    <div v-if="loadData">
      <div>
        <div class="content-header">
          <div class="content-header-left">
            <h1>{{ channel.name }}</h1>
          </div>
          <div class="content-header-right">
            <button class="btn-danger" @click="showModal = true">Block channel</button>
          </div>
        </div>

        <div class="tabs-wrap">
          <div
            v-for="(tabsItem, idx) in tabsItems"
            :key="idx"
            :class="['tabs-item', { 'tabs-item-active': tabsItem === activeTab }]"
            @click="changeTab(tabsItem)"
          >
            {{ tabsItem }}
          </div>
        </div>

        <div v-if="0" class="user-table-wrap">
          <div class="user-table-row">
            <div class="user-table-left">Name</div>
            <div class="user-table-right">{{ channel.name }}</div>
          </div>

          <div class="user-table-row">
            <div class="user-table-left">Title</div>
            <div class="user-table-right">{{ channel.title }}</div>
          </div>

          <div class="user-table-row">
            <div class="user-table-left">Description</div>
            <div class="user-table-right">{{ channel.description }}</div>
          </div>

          <div class="user-table-row">
            <div class="user-table-left">Status</div>
            <div
              :class="['user-table-right user-table-role', { 'user-table-role-active': channel.status === 'ACTIVE' }]"
            >
              <div>{{ channel.status }}</div>
              <img
                v-if="channel.status === 'ACTIVE'"
                src="/img/app/lock.svg"
                alt=""
                class="lock-ico"
                @click="showModal = true"
              />
              <img v-else src="/img/app/unlock.svg" alt="" class="lock-ico" @click="showModal = true" />
            </div>
          </div>
        </div>

        <div v-if="0" class="btn-wrap">
          <button class="btn-primary">Save</button>
          <router-link to="/channels" class="cancel-btn">Cancel</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import dayjs from 'dayjs'
  var relativeTime = require('dayjs/plugin/relativeTime')
  dayjs.extend(relativeTime)

  export default {
    name: 'User',
    data() {
      return {
        channel: {},
        loadData: false,

        showModal: false,
        reasonsBanList: ['Причина 1', 'Причина 2', 'Причина 3', 'Причина 4', 'Причина 5', 'Other'],
        curentResonBan: 'Причина 1',
        banReasonText: '',

        tabsItems: ['User', 'Video', 'Timetable'],
        activeTab: 'User',
      }
    },
    async created() {
      this.channel = await this.$api.channels.get(this.$route.params.id)
      this.loadData = true
    },
    methods: {
      changeTab(tabName) {
        this.activeTab = tabName
      },
      async banUser() {
        let data = {
          banReason: this.curentResonBan,
          banDate: new Date(),
        }

        if (this.banReasonText) {
          data.banReason = this.banReasonText
        }

        let res = await this.$api.channels.ban(this.channel.id, data)

        let alertData = {}
        if (res instanceof Error) {
          alertData.alertType = 'error'
          alertData.alertMessage = 'Something went wrong'
          this.$store.commit('setAlert', alertData)
        } else {
          alertData.alertType = 'success'
          alertData.alertMessage = 'channel successfully banned'
          this.$store.commit('setAlert', alertData)
          this.channel.status = res.status
        }

        this.curentResonBan = this.reasonsBanList[0]
        this.banReasonText = ''
        this.showModal = false
      },
      async unbanUser() {
        let res = await this.$api.channels.unban(this.channel.id)
        this.showModal = false

        let alertData = {}
        if (res instanceof Error) {
          alertData.alertType = 'error'
          alertData.alertMessage = 'Something went wrong'
          this.$store.commit('setAlert', alertData)
        } else {
          alertData.alertType = 'success'
          alertData.alertMessage = 'channel unlocked successfully'
          this.$store.commit('setAlert', alertData)
          this.channel.status = res.status
        }
      },
      formatDate(date) {
        return dayjs(date).format('MMMM DD, YYYY')
      },
    },
  }
</script>

<style lang="scss" scoped src="../assets/scss/users/edit-user.scss" />
