<template>
  <div class="create-employee-wrap">
    <div v-if="dataLoaded" class="create-employee">
      <h1>Edit employee</h1>
      <form @submit.prevent="send">
        <div class="form-group form-group-checkbox" @click="changeStatus">
          <div :class="['checkbox', { 'ckeckbox-checked': employee.status === 'ACTIVE' }]" />
          <span class="checkbox-text">Active</span>
        </div>

        <div class="form-group">
          <label for="full-name">Full name</label>
          <input id="full-name" v-model.trim="employee.fullName" type="text" />
          <div v-if="!$v.employee.fullName.minLength" class="msg-error">Full Name must be minimum 2 characters</div>
        </div>

        <div class="form-group">
          <label for="email">Email</label>
          <input id="email" v-model="employee.email" type="text" disabled />
        </div>

        <div class="form-group role-list-wrap">
          <label for="role">Role</label>
          <div class="role-list-item-current" @click="showRoleList = !showRoleList">
            <span>{{ currentRole }}</span>

            <img src="/img/app/arrow-down.svg" alt="" class="dropdown-icon" />
          </div>
          <div v-if="showRoleList" v-click-outside="() => (showRoleList = false)" class="role-list-items">
            <div v-for="(role, idx) in roleList" :key="idx" class="role-list-item" @click="changeRole(role)">
              {{ role }}
              <img v-if="role === currentRole" src="/img/app/selected.svg" alt="" class="selected-icon" />
            </div>
          </div>
        </div>

        <div class="form-btn-group">
          <button type="submit" class="btn-primary">Save</button>
          <router-link to="/employees" class="cancel-btn">Cancel</router-link>
          <!-- <button v-if="employee.deleted === 'NOT_DELETED'" class="btn-primary del-btn" @click="deleteAdmin">Delete</button>
                    <button v-else class="btn-primary del-btn" @click="restoreAdmin">Restore</button> -->
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import { minLength } from 'vuelidate/lib/validators'

  export default {
    name: 'CreateEmployee',
    data() {
      return {
        employee: {},

        currentRole: '',
        roleList: ['Administrator', 'Moderator'],
        showRoleList: false,

        firstDataRole: '',
        firstDataStatus: '',

        checked: true,
        dataLoaded: false,
        onSend: false,
      }
    },
    watch: {
      currentRole() {
        if (this.currentRole === 'Administrator') {
          this.employee.role = 'ADMIN'
        } else {
          this.employee.role = 'MODERATOR'
        }
      },
    },
    async created() {
      const res = await this.$api.employees.get(this.$route.params.id)
      this.employee = res

      if (this.employee.role === 'ADMIN') {
        this.currentRole = 'Administrator'
      } else {
        this.currentRole = 'Moderator'
      }

      this.firstDataRole = this.employee.role
      this.firstDataStatus = this.employee.status

      this.dataLoaded = true
    },
    methods: {
      changeRole(role) {
        this.currentRole = role
        this.showRoleList = false
      },
      changeStatus() {
        // QUESTION - переделать обернув в label?
        if (this.employee.status === 'ACTIVE') {
          this.employee.status = 'BANNED'
        } else {
          this.employee.status = 'ACTIVE'
        }
      },
      async send() {
        if (this.onSend) return false
        this.onSend = true

        if (this.firstDataStatus !== this.employee.status) {
          if (this.employee.status === 'BANNED') {
            await this.$api.employees.ban(this.employee.id, { banReason: 'default' })
            this.firstDataStatus = this.employee.status = 'BANNED'
          } else if (this.employee.status === 'ACTIVE') {
            await this.$api.employees.unban(this.employee.id)
            this.firstDataStatus = this.employee.status = 'ACTIVE'
          }
        }

        let data = {
          fullName: this.employee.fullName,
        }
        if (this.firstDataRole !== this.employee.role) {
          //QUESTION - надо ли делать проверку на изменение данных, и если ее не было то не отправлять запрос
          data.role = this.employee.role
          this.firstDataRole = this.employee.role
        }

        let res = await this.$api.employees.edit(this.employee.id, data)

        let alertData = {}
        if (res instanceof Error) {
          alertData.alertType = 'error'

          if (res.response.data.error === 'Bad Request') {
            alertData.alertMessage = res.response.data.message[0]
          } else {
            alertData.alertMessage = 'Something went wrong'
          }

          this.$store.commit('setAlert', alertData)
          this.onSend = false
          return false
        } else {
          alertData.alertType = 'success'
          alertData.alertMessage = 'data updated successfully'

          this.$store.commit('setAlert', alertData)
          this.onSend = false

          this.$router.push({ name: 'Employees' })
        }
      },
      async deleteAdmin() {
        if (this.onSend) return false
        this.onSend = true

        let res = await this.$api.employees.delete(this.employee.id)
        this.employee.deleted = res.deleted

        this.onSend = false
      },
      async restoreAdmin() {
        if (this.onSend) return false
        this.onSend = true

        let res = await this.$api.employees.restore(this.employee.id)
        this.employee.deleted = res.deleted

        this.onSend = false
      },
    },
    validations: {
      employee: {
        fullName: {
          minLength: minLength(2),
        },
      },
    },
  }
</script>

<style lang="scss" src="../assets/scss/employee/create-employee.scss" />
