<template>
  <div class="page-default page-channels">
    <v-app>
      <v-card-title>
        Channels
        <v-spacer />
        <v-text-field
          v-model="searchText"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          @keyup.enter="sendSearch"
        />
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="channels"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :footer-props="rowsPerPageItems"
        :items-per-page="itemsPerPage"
        item-key="id"
        class="elevation-1"
      >
        <template v-slot:[`item.name`]="{ item }">
          <div class="channels-table__name-wrapper">
            <img
              v-if="item.backgroundUrl && !item.isImageBroken"
              :src="item.backgroundUrl"
              style="width: 32px; height: 32px; border-radius: 16px; float: left"
              @error="handleChannelImageError(item)"
            />
            <h2 v-else>{{ item.name[0] + item.name[1] }}</h2>
            <div class="channels-table__name">{{ item.name }}</div>
          </div>
        </template>

        <template v-slot:[`item.email`]="{ item }">
          {{ item.email }}
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <div
            :class="[
              'status-inactive',
              {
                'status-active': item.status === 'ACTIVE',
              },
            ]"
          >
            {{ formatStatus(item.status) }}
          </div>
        </template>
        <template v-slot:[`item.lastActivityDate`]="{ item }">
          <div>{{ formatDate(item.lastActivityDate) }}</div>
          <div class="table-sub-title">{{ formatDateAgo(item.lastActivityDate) }}</div>
        </template>
      </v-data-table>
    </v-app>
  </div>
</template>
<script>
  export default {
    name: 'Channels',
    data() {
      return {
        channels: [],
        meta: {},
        params: {
          searchLike: null,
          sort: 'id',
          order: 'ASC',
          take: 10,
          page: 1,
        },

        headers: [
          { text: 'id', value: 'id', sortable: false },
          { text: 'name', value: 'name', sortable: false },
          { text: 'title', value: 'title', sortable: false },
          { text: 'description', value: 'description' },
          { text: 'status', value: 'status' },
          { text: 'followersCount', value: 'followersCount' },
          { text: 'subscribersCount', value: 'subscribersCount' },
          { text: 'userId', value: 'userId' },
          { text: 'deleted', value: 'deleted' },
          { text: 'createdAt', value: 'createdAt' },
        ],

        searchText: '',
        sortBy: 'id',
        sortDesc: false,
        itemsPerPage: 10,
        totalItems: 0,
        options: {},
        rowsPerPageItems: { 'items-per-page-options': [10, 25, 50, 100] },
        loading: true,
        onSend: false,
      }
    },
    watch: {
      options: {
        handler() {
          this.getData()
        },
        deep: true,
      },
    },
    mounted() {
      for (let prop in this.$route.query) {
        this.params[prop] = this.$route.query[prop]
      }

      if (this.params.searchLike) {
        this.searchText = this.params.searchLike
      }
      if (this.params.page) {
        this.options.page = +this.params.page
      }
      if (this.params.take) {
        this.itemsPerPage = this.params.take
        this.options.itemsPerPage = this.params.take
      }
      if (this.params.sort) {
        this.sortBy = this.params.sort
        this.options.sortBy = this.params.sort
      }
      if (this.params.order) {
        if (this.params.order === 'DESC') {
          this.options.sortDesc = true
          this.sortDesc = true
        } else {
          this.options.sortDesc = false
          this.sortDesc = false
        }
      }
      this.getFirstData()
    },
    methods: {
      handleChannelImageError(item) {
        this.channels = this.channels.map((cItem) => {
          if (cItem.id === item.id) {
            cItem.isImageBroken = true
          }
          return cItem
        })
      },
      async getData() {
        if (this.onSend) return false
        this.onSend = true
        this.loading = true

        const { sortBy, sortDesc, page, itemsPerPage } = this.options

        this.params.take = itemsPerPage
        this.params.page = page
        this.params.sort = sortBy[0]

        if (sortDesc[0] === true) {
          this.params.order = 'DESC'
        } else if (sortDesc[0] === false) {
          this.params.order = 'ASC'
        } else {
          this.params.sort = 'id'
          this.params.order = 'ASC'
        }

        if (this.params.searchLike === null) {
          let query = {
            take: this.params.take,
            page: this.params.page,
            sort: this.params.sort,
            order: this.params.order,
          }
          this.$router.push({ name: 'Channels', query })
        } else {
          this.$router.push({ name: 'Channels', query: this.params })
        }

        let res = await this.$api.channels.getAll(this.params)

        this.channels = res.data
        console.log('channels', this.channels)
        this.totalItems = res.meta.itemCount
        this.onSend = false
        this.loading = false
      },
      async getFirstData() {
        this.onSend = true
        this.loading = true

        let res = await this.$api.channels.getAll(this.params)

        this.channels = res.data
        console.log('channels', this.channels)
        this.totalItems = res.meta.itemCount

        this.onSend = false
        this.loading = false
      },
      sendSearch() {
        this.options.page = 1
        if (this.params.searchLike === '') {
          this.params.searchLike = null
        } else {
          this.params.searchLike = this.searchText
        }
        this.getData()
      },
      formatStatus(status) {
        if (status === 'ACTIVE') {
          return 'Active'
        } else if (status === 'BANNED') {
          return 'Banned'
        }
        return 'Undefined'
      },
      formatDate(date) {
        return this.$dayjs(date).format('MMMM DD, YYYY')
      },
      formatDateAgo(date) {
        return this.$dayjs(date).fromNow()
      },
      //vuetify
      toggleOrder() {
        this.sortDesc = !this.sortDesc
      },
      nextSort() {
        let index = this.headers.findIndex((h) => h.value === this.sortBy)
        index = (index + 1) % this.headers.length
        this.sortBy = this.headers[index].value
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/layout/page.scss';

  .channels-table {
    &__name {
      margin-left: 1em;
      color: grey;

      &-wrapper {
        display: flex;
        align-items: center;

        img {
          object-fit: cover;
        }
      }
    }
  }
</style>
