var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-default page-channels"},[_c('v-app',[_c('v-card-title',[_vm._v(" Channels "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.sendSearch.apply(null, arguments)}},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.channels,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"footer-props":_vm.rowsPerPageItems,"items-per-page":_vm.itemsPerPage,"item-key":"id"},on:{"update:options":function($event){_vm.options=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"channels-table__name-wrapper"},[(item.backgroundUrl && !item.isImageBroken)?_c('img',{staticStyle:{"width":"32px","height":"32px","border-radius":"16px","float":"left"},attrs:{"src":item.backgroundUrl},on:{"error":function($event){return _vm.handleChannelImageError(item)}}}):_c('h2',[_vm._v(_vm._s(item.name[0] + item.name[1]))]),_c('div',{staticClass:"channels-table__name"},[_vm._v(_vm._s(item.name))])])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.email)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{class:[
            'status-inactive',
            {
              'status-active': item.status === 'ACTIVE',
            } ]},[_vm._v(" "+_vm._s(_vm.formatStatus(item.status))+" ")])]}},{key:"item.lastActivityDate",fn:function(ref){
          var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.formatDate(item.lastActivityDate)))]),_c('div',{staticClass:"table-sub-title"},[_vm._v(_vm._s(_vm.formatDateAgo(item.lastActivityDate)))])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }