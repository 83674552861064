<template>
  <div class="auth-block-wrap">
    <div v-if="currentBlock === 'Set password'" class="auth-block">
      <div class="auth-block-title">Set password</div>
      <form @submit.prevent="submitNewPassword">
        <div class="form-group">
          <label for="new-password">Password</label>
          <input
            id="new-password"
            v-model="newPassword"
            type="password"
            autocomplete="off"
            :class="{ error: submitted && $v.newPassword.$error }"
          />

          <div v-if="submitted && $v.newPassword.$error">
            <div v-if="!$v.newPassword.required" class="msg-error">
              <img src="/img/app/error.svg" alt="" class="error-icon" />
              Password is required
            </div>
            <div v-if="!$v.newPassword.minLength" class="msg-error">
              <img src="/img/app/error.svg" alt="" class="error-icon" />
              Password must be minimum 8 characters
            </div>
            <div v-if="!$v.newPassword.maxLength" class="msg-error">
              <img src="/img/app/error.svg" alt="" class="error-icon" />
              Password must be maximum 50 characters
            </div>
            <div v-if="!$v.newPassword.password" class="msg-error">
              <img src="/img/app/error.svg" alt="" class="error-icon" />
              Password must contain only letters, numbers and special characters !#$%&*@^_-
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="new-password-confirm">Confirm Password</label>
          <input
            id="new-password-confirm"
            v-model="newPasswordConfirm"
            type="password"
            autocomplete="off"
            :class="{ error: submitted && $v.newPasswordConfirm.$error }"
          />

          <div v-if="submitted && $v.newPasswordConfirm.$error">
            <div v-if="!$v.newPassword.required" class="msg-error">
              <img src="/img/app/error.svg" alt="" class="error-icon" />
              Confirm Password is required
            </div>
            <div v-if="newPasswordConfirm && !$v.newPasswordConfirm.sameAsPassword" class="msg-error">
              <img src="/img/app/error.svg" alt="" class="error-icon" />
              Password and Confirm Password should match
            </div>
          </div>
        </div>

        <button type="submit" class="btn-submit">Set New Password</button>
      </form>
    </div>

    <div v-if="currentBlock === 'Set password msg'" class="auth-block">
      <div class="auth-block-title">Set password</div>

      <div class="auth-block-des">New password has been successfully set</div>

      <router-link to="/auth" class="btn-go-login">Back To Login</router-link>
    </div>
  </div>
</template>

<script>
  import { required, minLength, maxLength, sameAs } from 'vuelidate/lib/validators'

  export default {
    name: 'Auth',
    data() {
      return {
        newPassword: '', // Password1!
        newPasswordConfirm: '',

        currentBlock: 'Set password',
        submitted: false,
      }
    },
    methods: {
      async submitNewPassword() {
        this.submitted = true
        this.$v.$touch()

        if (this.$v.$invalid) {
          return false
        }

        await this.$api.profile.registrationSetPassword(this.$route.query.code, { password: this.newPassword })

        this.currentBlock = 'Set password msg'
      },
    },
    validations: {
      newPassword: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(50),
        password(value) {
          // remane
          return /^[\w!#$%&*@^-]*$/.test(value)
        },
      },
      newPasswordConfirm: { required, sameAsPassword: sameAs('newPassword') },
    },
  }
</script>

<style lang="scss" scoped src="../../assets/scss/auth/auth.scss" />
