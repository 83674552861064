<template>
  <div class="alert-wrap">
    <div
      :class="[
        'alert',
        {
          'alert-error': alertType === 'error',
          'alert-success': alertType === 'success',
          'alert-info': alertType === 'info',
        },
      ]"
    >
      <div class="alert-text">
        <strong>{{ alertType[0].toUpperCase() + alertType.slice(1) }}</strong
        >: {{ alertMessage }}
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'Alert',
    computed: {
      ...mapState(['alertType', 'alertMessage']),
    },
  }
</script>

<style lang="scss" src="../../assets/scss/components/elements/alert.scss" />
