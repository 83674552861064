var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-default page-broadcasts"},[_c('v-app',[_c('v-card-title',[_vm._v(" Broadcasts "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.sendSearch.apply(null, arguments)}},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.broadcasts,"options":_vm.options,"server-items-length":_vm.totalItrms,"loading":_vm.loading,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"footer-props":_vm.rowsPerPageItems,"items-per-page":_vm.itemsPerPage,"item-key":"id"},on:{"update:options":function($event){_vm.options=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('img',{staticStyle:{"width":"42px","height":"32px","border-radius":"5px","float":"left","object-fit":"cover"},attrs:{"src":item.previewPath,"alt":""}}),_c('div',{staticClass:"table-sub-title table-sub-title--short",staticStyle:{"margin":"8px 0 0 6px","float":"left"}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.formatDate(item.createdAt)))]),_c('div',{staticClass:"table-sub-title"},[_vm._v(_vm._s(_vm.formatDateAgo(item.createdAt)))])]}},{key:"item.scheduledStartDate",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.formatDate(item.scheduledStartDate)))]),_c('div',{staticClass:"table-sub-title"},[_vm._v(_vm._s(_vm.formatDateAgo(item.createdAt)))])]}},{key:"item.categories",fn:function(ref){
var item = ref.item;
return _vm._l((item.categories),function(cat,idx){return _c('span',{key:idx,staticClass:"test-111"},[_vm._v(" "+_vm._s(cat.title)+_vm._s(idx !== item.categories.length - 1 ? ',' : '')+" ")])})}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }