<template>
  <div class="page-default page-broadcast-cats">
    <v-app>
      <v-data-table
        :key="dataTableKey"
        :headers="headers"
        :items="videos"
        item-key="id"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :footer-props="rowsPerPageItems"
        :items-per-page="10"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-card-title>VoD</v-card-title>
            <v-divider class="mx-4" inset vertical />
            <v-spacer />

            <v-text-field
              v-model="searchText"
              class="mx-4"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              @keyup.enter="sendSearch"
            />

            <!-- CREATE/EDIT BROADCAST DIALOG -->
            <v-dialog v-model="dialogBroadcastForm" persistent max-width="510px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark class="mb-2 mr-2" v-bind="attrs" v-on="on"> New broadcast </v-btn>
              </template>
              <v-card>
                <v-card-title class="mt-0">
                  <span class="headline">{{ broadcastFormTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field v-model="currentBroadcast.name" label="Name" />
                        <v-textarea
                          v-model="currentBroadcast.description"
                          name="broadcast-description"
                          label="Description"
                          hint="Description text..."
                        />
                        <v-text-field v-model.number="currentBroadcast.channelId" label="Channel ID" />
                        <v-select
                          v-model="currentBroadcast.categories"
                          :items="categories"
                          item-text="title"
                          item-value="id"
                          label="Categories"
                          chips
                          clearable
                          multiple
                          @input="handleCategory($event)"
                        />
                        <v-row>
                          <v-col cols="2" sm="2" md="2">
                            <div
                              class="file-prevew"
                              :style="`background-image: url(${
                                newPreviewImage ? newPreviewImage : currentBroadcast.previewPath
                              });`"
                            />
                          </v-col>
                          <v-col cols="10" sm="10" md="10">
                            <v-file-input
                              v-model="newImageFile"
                              show-size
                              accept="image/*"
                              label="Preview"
                              @change="onFileChange"
                            />
                          </v-col>
                        </v-row>
                        <v-select v-model="currentBroadcast.access" :items="['ALL', 'SPONSORS']" label="Access" />
                        <v-text-field
                          v-if="currentBroadcast.access === 'SPONSORS'"
                          v-model.number="currentBroadcast.price"
                          label="Price"
                        />
                        <div>
                          <v-checkbox v-model="currentBroadcast.onlyForSubscribers" label="For subscribers only" />
                          <v-checkbox v-model="currentBroadcast.viewOnMainPage" label="Is on main page" />
                          <v-checkbox v-model="currentBroadcast.viewOnMainVideo" label="Is main video" />
                        </div>
                        <div v-if="currentBroadcast.isPlanned">
                          <v-date-picker v-model="startDate" full-width />
                          <span class="mr-2" />
                          <v-time-picker v-model="startTime" format="24hr" scrollable full-width />
                        </div>
                        <div class="progress-wrap">
                          <v-progress-linear v-if="onSend" color="primary accent-4" indeterminate rounded height="6" />
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-file-input show-size accept="video/*" label="Video" @change="onFileChange" />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="closeBroadcastForm"> Cancel </v-btn>
                  <v-btn color="blue darken-1" text @click="submitBroadcast"> Save </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogDelete" max-width="510px">
              <v-card>
                <v-card-title class="headline mt-0">
                  Are you sure you want to {{ deleteRestoreTitle }} this item?
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="closeDelete"> Cancel </v-btn>
                  <v-btn v-if="deleteRestoreTitle === 'delete'" color="blue darken-1" text @click="deleteItemConfirm">
                    OK
                  </v-btn>
                  <v-btn v-else color="blue darken-1" text @click="restoreItemConfirm"> OK </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <!-- TABLE DATA -->
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex justify-space-between">
            <v-icon medium class="mr-2" @click="openNewStreamForm(item)"> mdi-video-plus </v-icon>
            <v-icon small class="mr-2" @click="openEditBroadcast(item, item.id)"> mdi-pencil </v-icon>
            <v-icon v-if="isItemHasVod(item)" class="mr-2" @click="openVodPlayer(item)"> mdi-play </v-icon>
            <v-icon v-if="item.deleted === 'NOT_DELETED'" small @click="deleteItem(item)"> mdi-delete </v-icon>
            <!-- <v-icon v-else small @click="restoreItem(item)"> mdi-backup-restore </v-icon> -->
          </div>
        </template>

        <template v-slot:[`item.title`]="{ item }">
          <div style="display: flex; align-items: center">
            <img
              :src="getPreviewPath(item)"
              style="width: 42px; height: 32px; border-radius: 5px; object-fit: cover; margin-right: 10px"
            />
            <div class="table-sub-title table-sub-title--short" style="">
              {{ item.name }}
            </div>
          </div>
        </template>
        <template v-slot:[`item.author`]="{ item }">
          <div class="table-sub-title" style="margin: 8px 0 0 6px; float: left">
            {{ item.channelIds ? setChannel(item.channelIds[0]) : '' }}
          </div>
        </template>
        <template v-slot:[`item.published`]="{ item }">
          <div class="table-sub-title" style="margin: 8px 0 0 6px; float: left">
            {{ item.isPublished }}
          </div>
        </template>
        <template v-slot:[`item.deleted`]="{ item }">
          <div
            :class="[
              'status-inactive',
              {
                'status-active': item.deleted === 'NOT_DELETED',
              },
            ]"
          >
            {{ item.deleted }}
          </div>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
          <div>{{ formatDate(item.createdAt) }}</div>
          <div class="employee-table-sub-title">
            {{ formatDateAgo(item.createdAt) }}
          </div>
        </template>
        <template v-slot:[`item.description`]="{ item }">
          <div>
            {{ item.description.length > 100 ? item.description.slice(0, 100) : item.description }}
          </div>
        </template>
        <template v-slot:[`item.categories`]="{ item }">
          <div>
            <span v-for="(cat, idx) in item.categories" :key="idx" class="test-111">
              {{ cat.title }}{{ idx !== item.categories.length - 1 ? ',' : '' }}
            </span>
          </div>
        </template>
      </v-data-table>
    </v-app>

    <br />
    <br />
    <br />
    <v-dialog v-model="dialogStreamForm" width="600">
      <v-card>
        <v-card-title class="headline mt-0">Add stream</v-card-title>

        <v-card-text>
          <v-container>
            <v-text-field v-model="newStream.name" label="Name" />
            <v-select
              :items="vodList"
              item-text="name"
              :item-value="(item) => item"
              label="VoD URL"
              @change="handleVodChange"
            />
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="cancelStreamAdd"> Cancel </v-btn>
          <v-btn color="blue darken-1" text @click="submitStream"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  export default {
    name: 'VidosOnDemand',
    data() {
      return {
        videos: [],
        meta: {},

        vodList: [],

        params: {
          searchLike: null,
          sort: 'id',
          order: 'DESC',
          take: 10,
          page: 1,
        },
        headers: [
          { text: 'id', value: 'id', align: 'start' },
          { text: 'title', value: 'title', sortable: false },
          { text: 'description', value: 'description', sortable: false },
          { text: 'categories', value: 'categories', sortable: false, width: '300px' },
          { text: 'author', value: 'author', sortable: false },
          { text: 'published', value: 'published', sortable: false },
          { text: 'deleted', value: 'deleted', sortable: false },
          { text: 'created At', value: 'createdAt', sortable: false },
          { text: 'actions', value: 'actions', sortable: false },
        ],

        dialogStreamForm: false,
        dialogBroadcastForm: false,
        dialogDelete: false,

        currentBroadcast: {
          id: 0,
          channelId: 0,
          name: '',
          type: 'STANDARD',
          access: 'ALL',
          hasChat: true,
          isPlanned: false,
          isPublished: false,
          onlyForSubscribers: false,
          categories: [],
          previewPath: '',
          price: 10000,
          description: '',
        },
        startDate: new Date().toISOString().substr(0, 10),
        startTime: '12:00',

        dataTableKey: 0,

        initialCategoryIds: [],
        addCategoryIds: [],
        removeCategoryIds: [],

        newStream: {},
        categories: [],
        channels: [],

        editedIndex: -1,

        editedItemId: null,
        deleteRestoreTitle: 'delete',

        totalItems: 0,
        searchText: '',
        sortBy: 'id',
        sortDesc: true,
        itemsPerPage: 10,
        options: {},
        rowsPerPageItems: { 'items-per-page-options': [10, 25, 50, 100] },
        loading: true,

        newVideoFile: null,
        videoFileName: null,

        newImageFile: null,
        newPreviewImage: null,

        onUploadVideo: false,
        onSend: false,
      }
    },
    computed: {
      broadcastFormTitle() {
        return this.editedIndex === -1 ? 'New Broadcast' : 'Edit Broadcast'
      },
    },
    watch: {
      options: {
        handler() {
          this.getData()
        },
        deep: true,
      },
      dialogDelete(val) {
        // val || this.closeDelete()
      },
    },
    mounted() {
      for (let prop in this.$route.query) {
        this.params[prop] = this.$route.query[prop]
      }

      if (this.params.searchLike) {
        this.searchText = this.params.searchLike
      }
      if (this.params.page) {
        this.options.page = +this.params.page
      }
      if (this.params.take) {
        this.itemsPerPage = this.params.take
        this.options.itemsPerPage = this.params.take
      }
      if (this.params.sort) {
        this.sortBy = this.params.sort
        this.options.sortBy = this.params.sort
      }
      if (this.params.order) {
        if (this.params.order === 'DESC') {
          this.options.sortDesc = true
          this.sortDesc = true
        } else {
          this.options.sortDesc = false
          this.sortDesc = false
        }
      }
      this.getFirstData()
    },
    methods: {
      getPreviewPath(item) {
        return item?.resizedPreview?.cat_preview_s?.png
      },
      handleVodChange(item) {
        this.newStream.vodUrl = item.vodUrl
        this.newStream.bunnyId = item.bunnyId
      },
      async getData() {
        if (this.onSend) return false
        this.onSend = true
        this.loading = true
        console.log(this.options)
        const { sortBy, sortDesc, page, itemsPerPage } = this.options

        this.params.take = itemsPerPage
        this.params.page = page
        this.params.sort = 'id'

        if (sortDesc[0] === true) {
          this.params.order = 'DESC'
        } else if (sortDesc[0] === false) {
          this.params.order = 'ASC'
        } else {
          this.params.sort = 'id'
          this.params.order = 'ASC'
        }

        if (this.params.searchLike === null) {
          let query = {
            take: this.params.take,
            page: this.params.page,
            sort: this.params.sort,
            order: this.params.order,
          }
          this.$router.push({ name: 'VoD', query })
        } else {
          this.$router.push({ name: 'VoD', query: this.params })
        }
        let res = await this.$api.streams.getAllBroadcasts(this.params)

        this.videos = res.data
        this.totalItems = res.meta.itemCount
        this.onSend = false
        this.loading = false
      },
      async getFirstData() {
        this.onSend = true
        this.loading = true

        let res = await this.$api.streams.getAllBroadcasts(this.params)
        console.log('🚀 ~ file: VideosOnDemand.vue ~ line 300 ~ getFirstData ~ res', res)

        this.videos = res.data
        this.totalItems = res.meta.itemCount

        let vod = await this.$api.uploader.getVoD({ take: 500 })
        this.vodList = vod.data

        let channels = await this.$api.channels.getAll({ take: 500 })
        this.channels = channels.data

        let cats = await this.$api.streams.getCategories({ take: 500 })
        this.categories = cats.data

        this.onSend = false
        this.loading = false
      },
      setChannel(channelId) {
        let channel = this.channels.find((channel) => channel.id === channelId)
        return channel?.title
      },
      sendSearch() {
        this.options.page = 1
        if (this.params.searchLike === '') {
          this.params.searchLike = null
        } else {
          this.params.searchLike = this.searchText
        }
        this.getData()
      },
      formatStatus(status) {
        if (status === 'ACTIVE') {
          return 'Active'
        } else if (status === 'BANNED') {
          return 'Banned'
        }
        return 'Undefined'
      },
      formatDate(date) {
        return this.$dayjs(date).format('MMMM DD, YYYY')
      },
      formatDateAgo(date) {
        return this.$dayjs(date).fromNow()
      },

      handleCategory(categories) {
        this.addCategoryIds = categories.filter((cat) => this.initialCategoryIds.indexOf(cat) == -1)
        this.removeCategoryIds = this.initialCategoryIds.filter((cat) => categories.indexOf(cat) == -1)
      },

      async submitBroadcast() {
        let newBroadcast = {
          channelId: Number(this.currentBroadcast.channelId),
          categoryIds: this.currentBroadcast.categories,
          ...this.currentBroadcast,
        }
        delete newBroadcast.categories
        delete newBroadcast.isPublished
        delete newBroadcast.scheduledStartDate

        if (this.editedIndex > -1) {
          this.$set(newBroadcast, 'addCategoryIds', this.addCategoryIds)
          this.$set(newBroadcast, 'removeCategoryIds', this.removeCategoryIds)
          // this.$set(newBroadcast, 'scheduledStartDate', this.startDate + 'T' + this.startTime + ':00.000Z')

          let res = await this.$api.streams.updateBroadcast(this.currentBroadcast.id, newBroadcast)
          if (res) {
            this.videos[this.editedIndex].viewOnMainVideo = this.currentBroadcast.viewOnMainVideo
            this.editedIndex = -1
            this.currentBroadcast = {}
          }
        } else {
          newBroadcast.type = 'STANDARD'
          const newBroadcastData = new FormData()
          newBroadcastData.append('files', this.newVideoFile)
          newBroadcastData.append('channelId', newBroadcast.channelId)
          newBroadcastData.append('categoryIds', newBroadcast.categoryIds)
          newBroadcastData.append('previewPath', this.newPreviewImage)
          newBroadcastData.append('title', newBroadcast.name)
          newBroadcastData.append('description', newBroadcast.description)
          newBroadcastData.append('name', newBroadcast.name)
          let res = await this.$api.streams.createVod(newBroadcastData)
          if (res) {
            this.videos.unshift(res)
            this.currentBroadcast = {}
          }
        }
        this.dialogBroadcastForm = false
      },
      isItemHasVod(item) {
        if (item?.streams?.length) {
          return item.streams[0]?.vodUrl
        }
        return false
      },
      openVodPlayer(item) {
        if (this.isItemHasVod(item)) {
          this.$router.push({
            name: 'VodPlayer',
            params: { streamId: item.streams[0].id, broadcastId: item.id },
          })
        }
      },

      openNewStreamForm(item) {
        this.newStream.broadcastId = item.id
        this.newStream.channelId = item.channelIds[0]
        this.dialogStreamForm = true
      },

      async submitStream() {
        await this.$api.streams.createStream(this.newStream)
        this.cancelStreamAdd()
      },

      cancelStreamAdd() {
        this.newStream = {}
        this.dialogStreamForm = false
      },

      openEditBroadcast(item) {
        this.editedIndex = this.videos.indexOf(item)
        this.currentBroadcast.id = item.id
        this.initialCategoryIds = item.categories.map((cat) => cat.id)
        ;(this.startDate = item.scheduledStartDate?.split('T')[0]),
          (this.startTime = item.scheduledStartDate?.split('T')[1].slice(0, 5)),
          (this.currentBroadcast = { channelId: item.channelIds[0], ...item })

        this.dialogBroadcastForm = true
      },

      deleteItem(item) {
        console.log('delete', item)
        this.editedIndex = this.videos.indexOf(item)
        this.editedItemId = item.id
        this.deleteRestoreTitle = 'delete'
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      restoreItem(item) {
        this.editedIndex = this.videos.indexOf(item)
        this.deleteRestoreTitle = 'restore'
        this.editedCategoryId = item.id
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      async deleteItemConfirm() {
        let res = await this.$api.streams.deleteBroadcast(this.editedItemId)
        this.editedItemId = null

        var index = this.videos.indexOf(this.editedIndex)

        if (index !== -1) {
          this.videos[index].deleted = res.deleted
        }
        this.dataTableKey += 1
        this.closeDelete()
      },
      async restoreItemConfirm() {
        let res = await this.$api.streams.restoreStreamCategory(this.editedItemId)
        this.editedItemId = null
        var index = this.videos.indexOf(this.editedIndex)

        if (index !== -1) {
          this.videos[index].deleted = res.deleted
        }
        this.closeDelete()
      },
      closeBroadcastForm() {
        this.dialogBroadcastForm = false
        this.currentBroadcast = {}
        this.editedIndex = -1
      },
      closeDelete() {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      // upload files
      async onFileChange(event) {
        let file = event

        if (file.type.includes('image')) {
          this.newImageFile = file

          let reader = new FileReader()
          reader.onload = (e) => {
            this.newPreviewImage = e.target.result
          }
          reader.readAsDataURL(file)

          const formData = new FormData()
          formData.append('files', this.newImageFile)

          let res = await this.$api.uploader.uploadFile(formData)
          this.currentBroadcast.previewPath = await res.data[0].url
        } else {
          this.newVideoFile = file
        }
      },

      //vuetify
      toggleOrder() {
        this.sortDesc = !this.sortDesc
      },
      nextSort() {
        let index = this.headers.findIndex((h) => h.value === this.sortBy)
        index = (index + 1) % this.headers.length
        this.sortBy = this.headers[index].value
      },
    },
  }
</script>

<style lang="scss">
  input {
    border: none;
  }
  th.text-start {
    text-align: left;
  }
  .elevation-1 .text-start {
    font-weight: 600;
    font-size: 12px !important;
    line-height: 16px;
    color: #000000;
  }
  .v-data-table__wrapper {
    margin-top: 0;
    border-radius: 4px;
    border: 1px solid #c2c9d1;
    border-bottom: none;
  }
  .v-data-table-header {
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.5) !important;
    // padding: 26px 16px 14px;
    border-bottom: 1px solid #c2c9d1;
    border-right: 1px solid #c2c9d1;
    background-repeat: no-repeat;
    background-position: top 31px right 22px;
    cursor: pointer;
    position: relative;
  }
  .text-start a {
    color: #000000;
  }
  .sortable:before {
    content: '';
    position: absolute;
    width: 32px;
    height: 10px;
    right: 0px;
    background: url(/img/app/triangle.svg) 0 0 no-repeat;
  }
  .sortable.desc:before {
    background: url(/img/app/triangle-blue.svg) 0 0 no-repeat;
  }
  .sortable.asc:before {
    background: url(/img/app/triangle-blue.svg) 0 0 no-repeat;
    transform: rotate(180deg);
    background-position: 22px 6px;
  }
  th.text-start.sortable {
    position: relative;
  }
  .v-data-table-header__icon {
    display: none !important;
  }
  label {
    margin-top: 0px;
  }
  body .v-application .elevation-1 {
    box-shadow: none !important;
  }
  body .v-divider--vertical.v-divider--inset {
    display: none;
  }
  .file-prevew {
    height: 34px;
    width: auto;
    background-size: contain;
  }
  .progress-wrap {
    height: 6px;
  }
</style>

<style lang="scss" scoped>
  @import '@/assets/scss/layout/page.scss';
</style>
