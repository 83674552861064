import { getRequest, postRequest, deleteRequest } from './requests'

export default {
  async getAll() {
    return await getRequest(`legal/admin/legals/target/list`)
  },
  async getLegalDoc(docType) {
    return await getRequest(`legal/legals/${docType}`)
  },
  async changeLegalDoc(docType, data) {
    return await postRequest(`legal/admin/legals/${docType}`, data)
  },
  async deleteLegalDoc(docType) {
    return await deleteRequest(`legal/admin/legals/list/${docType}`)
  },
}
