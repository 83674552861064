import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Auth from '../views/Auth'
import setPassword from '../components/auth/setPassword'
import resetPassword from '../components/auth/resetPassword'
import Profile from '../views/Profile'
import Employees from '../views/Employees'
import CreateEmployee from '../views/CreateEmployee'
import EditEmployee from '../views/EditEmployee'
import Users from '../views/Users'
import EditUser from '../views/EditUser'
import Channels from '../views/Channels'
import EditChannel from '../views/EditChannel'
import Reports from '../views/Reports'
import VideosOnDemand from '../views/VideosOnDemand'
import Broadcasts from '../views/Broadcasts'
import Streams from '../views/Streams'
import Test from '@/views/Test'
import Files from '@/views/Files'
import StreamCategories from '@/views/StreamCategories'
import Legal from '@/views/Legal'
import LegalDoc from '@/views/LegalDoc'
import Moderation from '@/views/Moderation'
import ModerationPlayer from '@/views/ModerationPlayer'
import VodPlayer from '@/views/VodPlayer'
import Complaints from '@/views/ComplaintsView'

Vue.use(VueRouter)

const routes = [
  {
    path: '/test',
    name: 'test',
    component: Test,
    meta: {
      title: 'Test',
    },
  },
  {
    path: '/auth',
    name: 'auth',
    component: Auth,
    meta: {
      title: 'Authorization',
    },
  },
  {
    path: '/set-password',
    name: 'setPassword',
    component: setPassword,
    meta: {
      title: 'Set password',
    },
  },
  {
    path: '/reset-password',
    name: 'resetPassword',
    component: resetPassword,
    meta: {
      title: 'Reset password',
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      title: 'Profile',
    },
  },
  {
    path: '/employees',
    name: 'Employees',
    component: Employees,
    meta: {
      title: 'Employees',
    },
  },
  {
    path: '/employees/create',
    name: 'CreateEmployee',
    component: CreateEmployee,
    meta: {
      title: 'Create employee',
    },
  },
  {
    path: '/employees/edit/:id',
    name: 'EditEmployee',
    component: EditEmployee,
    meta: {
      title: 'Edit employee',
    },
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
    meta: {
      title: 'Users',
    },
  },
  {
    path: '/users/edit/:id',
    name: 'EditUser',
    component: EditUser,
    meta: {
      title: 'Edit user',
    },
  },
  {
    path: '/channels',
    name: 'Channels',
    component: Channels,
    meta: {
      title: 'Channels',
    },
  },
  {
    path: '/channels/edit/:id',
    name: 'EditChannel',
    component: EditChannel,
    meta: {
      title: 'Edit Channel',
    },
  },
  {
    path: '/reports',
    name: 'Reports',
    component: Reports,
    meta: {
      title: 'Reports',
    },
  },
  {
    path: '/vod',
    name: 'VoD',
    component: VideosOnDemand,
    meta: {
      title: 'VoD',
    },
  },
  {
    path: '/broadcasts',
    name: 'Broadcasts',
    component: Broadcasts,
    meta: {
      title: 'Broadcasts',
    },
  },
  {
    path: '/streams',
    name: 'Streams',
    component: Streams,
    meta: {
      title: 'Streams',
    },
  },
  {
    path: '/files',
    name: 'Files',
    component: Files,
    meta: {
      title: 'Files',
    },
  },
  {
    path: '/stream-categories',
    name: 'StreamCategories',
    component: StreamCategories,
    meta: {
      title: 'StreamCategories',
    },
  },
  {
    path: '/legal',
    name: 'Legal',
    component: Legal,
    meta: {
      title: 'Legal',
    },
  },

  {
    path: '/legal/:doc_type',
    name: 'LegalDoc',
    component: LegalDoc,
    meta: {
      title: 'LegalDoc',
    },
  },
  {
    path: '/moderation',
    name: 'Moderation',
    component: Moderation,
    meta: {
      title: 'Moderation',
    },
  },
  {
    path: '/moderation/:broadcastId/:streamId',
    name: 'ModerationPlayer',
    component: ModerationPlayer,
    meta: {
      title: 'Moderation Player',
    },
  },
  {
    path: '/vod/:broadcastId/:streamId',
    name: 'VodPlayer',
    component: VodPlayer,
    meta: {
      title: 'Vod Player',
    },
  },
  {
    path: '/complaints',
    name: 'Complaints',
    component: Complaints,
    meta: {
      title: 'Complaints',
    },
  },
]

const router = new VueRouter({
  mode: 'history',

  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  // base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  const adminMiddleware = to.matched.some((record) => record.path.startsWith('/employee'))
  if (adminMiddleware && store.state.admin.role !== 'ADMIN') {
    next('/')
  }

  const authorization = to.matched.some(
    (record) =>
      record.path.startsWith('/auth') ||
      record.path.startsWith('/reset-password') ||
      record.path.startsWith('/set-password')
  )
  if (localStorage.getItem('token')) {
    if (authorization) {
      return next('/')
    }
  } else if (!authorization) {
    return next('/auth')
  }
  store.commit('setShowHeaderNavbar', !authorization)

  if (!to.meta?.awaitTitle) {
    const defaultTitle = store.state.defaultTitle
    document.title = to.meta?.title ? `${to.meta?.title} - ${defaultTitle}` : defaultTitle
  }
  next()
})

export default router
