import Vue from 'vue'
import store from './store'
import App from './App.vue'
import api from '@/api/routes'
import vClickOutside from 'v-click-outside'
import router from './router'
import Vuelidate from 'vuelidate'
import Dayjs from 'dayjs'
import vuetify from '@/plugins/vuetify'
import marked from 'marked'

Vue.prototype.$dayjs = Dayjs
Vue.prototype.$api = api

Vue.use(vClickOutside)
Vue.use(Vuelidate)
Vue.use({
  install() {
    Vue.marked = marked
    Vue.prototype.$marked = marked
  },
})

Vue.config.productionTip = false

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
