<template>
  <section class="vod-player" data-app>
    <h1>{{ vod.name }}</h1>
    <video-player v-if="vodUrl" :video-url="vodUrl" class="video-player" />
  </section>
</template>

<script>
  import VideoPlayer from '../components/elements/VideoPlayer.vue'

  export default {
    components: {
      VideoPlayer,
    },
    data() {
      return {
        streamId: null,
        broadcastId: null,
        vodUrl: '',
        vod: {},
      }
    },
    async created() {
      this.streamId = this.$route.params.streamId
      this.broadcastId = this.$route.params.broadcastId

      const vod = await this.getVodByStreamId(this.streamId)
      this.vod = vod
      this.vodUrl = vod?.vodUrl
    },
    methods: {
      async getVodByStreamId(id) {
        const vod = await this.$api.streams.getById(id)
        return vod
      },
    },
  }
</script>

<style lang="scss" scoped>
  .vod-player {
    max-width: 820px;
    width: 100%;
    margin: 0 auto;
    margin-top: 2em;

    h1 {
      margin-bottom: 1em;
    }

    ::v-deep video {
      max-height: 50vh;
    }
  }
</style>
