<template>
  <div class="create-employee-wrap">
    <div class="create-employee">
      <h1>Create Employee</h1>
      <form @submit.prevent="inviteEmployee">
        <div class="form-horizontal-group">
          <div class="form-group">
            <label for="full-name">Full name</label>
            <input id="full-name" v-model="fullName" type="text" />
          </div>
        </div>

        <div class="form-group">
          <label for="email">Email</label>
          <input id="email" v-model="email" type="text" />
        </div>

        <div class="form-group role-list-wrap">
          <label for="role">Role</label>
          <div class="role-list-item-current" @click="showRoleList = !showRoleList">
            <span>{{ currentRole }}</span>

            <img src="/img/app/arrow-down.svg" alt="" class="dropdown-icon" />
          </div>
          <div v-if="showRoleList" v-click-outside="() => (showRoleList = false)" class="role-list-items">
            <div v-for="(role, idx) in roleList" :key="idx" class="role-list-item" @click="changeRole(role)">
              {{ role }}
              <img v-if="role === currentRole" src="/img/app/selected.svg" alt="" class="selected-icon" />
            </div>
          </div>
        </div>

        <div class="form-btn-group">
          <button type="submit" class="btn-primary">Create</button>
          <router-link to="/employees" class="cancel-btn">Cancel</router-link>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CreateEmployee',
    data() {
      return {
        fullName: '',
        email: '',
        currentRole: 'Administrator',

        showRoleList: false,
        roleList: ['Administrator', 'Moderator'],
      }
    },
    computed: {},
    methods: {
      changeRole(role) {
        this.currentRole = role
        this.showRoleList = false
      },
      async inviteEmployee() {
        let role = 'ADMIN'
        if (this.currentRole === 'Moderator') {
          role = 'MODERATOR'
        }

        const data = {
          fullName: this.fullName,
          email: this.email,
          role,
        }

        let res = await this.$api.employees.invite(data)

        let alertData = {}
        if (res instanceof Error) {
          alertData.alertType = 'error'
          if (res.response.data?.message === 'error.admin.alreadyExist') {
            alertData.alertMessage = 'this email is already registered in the system'
          } else {
            alertData.alertMessage = 'Something went wrong'
          }
          this.$store.commit('setAlert', alertData)
        } else {
          alertData.alertType = 'success'
          alertData.alertMessage = 'invitation sent successfully'
          this.$store.commit('setAlert', alertData)
        }
      },
    },
  }
</script>

<style lang="scss" scoped src="../assets/scss/employee/create-employee.scss" />
