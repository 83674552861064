<template>
  <div class="page-default page-channels">
    <v-app>
      <v-data-table
        :headers="headers"
        :items="complaints"
        :loading="loading"
        :footer-props="rowsPerPageItems"
        :items-per-page="10"
        :sort-by.sync="sortBy"
        :options.sync="options"
        :sort-desc.sync="sortDesc"
        :server-items-length="totalItems"
        item-key="id"
        class="elevation-1"
      >
        <template v-slot:[`item.title`]="{ item }">
          <div style="display: flex; align-items: center">
            <img
              :src="getBroadcastPreview(item.broadcast)"
              style="width: 42px; height: 32px; border-radius: 5px; object-fit: cover; margin-right: 10px"
            />
            <div class="table-sub-title table-sub-title--short" style="">
              {{ item.broadcast.name }}
            </div>
          </div>
        </template>
        <template v-slot:[`item.link`]="{ item }">
          <a :href="getBroadcastLink(item.broadcast)">{{ getBroadcastLink(item.broadcast) }}</a>
        </template>
        <template v-slot:[`item.user`]="{ item }">
          <div>{{ getUserName(item.user) }}</div>
        </template>
        <template v-slot:[`item.complaint`]="{ item }">
          <div>
            <span class="strong">Report Type: </span>
            {{ item.reportType }}
          </div>
          <div v-if="item.comment">
            <span class="strong">Comment: </span>
            {{ item.comment }}
          </div>
        </template>
      </v-data-table>
    </v-app>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        headers: [
          { text: 'ID', value: 'id', align: 'start' },
          { text: 'Title', value: 'title', sortable: false },
          { text: 'Link', value: 'link', sortable: false },
          { text: 'User', value: 'user', sortable: false },
          { text: 'Complaint', value: 'complaint', sortable: false },
        ],
        complaints: [],

        params: {
          sort: 'id',
          order: 'DESC',
          take: 50,
          page: 1,
        },
        sortBy: 'id',
        sortDesc: true,
        itemsPerPage: 50,
        options: {},
        rowsPerPageItems: { 'items-per-page-options': [10, 25, 50, 100] },
        loading: true,
        totalItems: 0,
      }
    },
    watch: {
      options: {
        handler() {
          this.getData()
        },
        deep: true,
      },
    },
    methods: {
      async getData() {
        this.loading = true
        const { sortDesc, page, itemsPerPage } = this.options

        this.params.take = itemsPerPage
        this.params.page = page
        this.params.sort = 'id'

        if (sortDesc[0] === true) {
          this.params.order = 'DESC'
        } else if (sortDesc[0] === false) {
          this.params.order = 'ASC'
        } else {
          this.params.sort = 'id'
          this.params.order = 'DESC'
        }

        const complaintsRes = await this.$api.streams.getReports(this.params)
        if (complaintsRes instanceof Error) {
          this.loading = false
        }
        console.log('=>', complaintsRes)
        this.totalItems = complaintsRes.meta?.itemCount || this.totalItems

        const reportedBroadcasts = await Promise.all(
          complaintsRes.data.map((report) => this.$api.streams.getBroadcastById(report.targetId))
        )

        const reportedBroadcastUsers = (
          await this.$api.users.getMap(reportedBroadcasts.map((broadcast) => broadcast.userId))
        )?.data
        console.log('reportedBroadcasts', reportedBroadcasts)
        console.log('reportedBroadcastUsers', reportedBroadcastUsers)

        this.complaints = complaintsRes.data
          .map((report) => ({
            ...report,
            broadcast: reportedBroadcasts.find((broadcast) => broadcast.id === report.targetId),
          }))
          .map((complaint) => ({
            ...complaint,
            user: reportedBroadcastUsers[complaint.broadcast.userId],
          }))
        this.loading = false
        console.log(this.complaints)
      },
      getBroadcastPreview(broadcast) {
        return broadcast?.resizedPreview?.avatar_160.webp || broadcast.previewPath
      },
      getBroadcastLink(broadcast) {
        if (document.location.host.includes('dev')) {
          return `https://dev.makestep.com/video/${broadcast.id}`
        }

        return `https://makestep.com/video/${broadcast.id}`
      },
      getUserName(user) {
        return user.username
      },
    },
  }
</script>

<style>
  span.strong {
    font-weight: bold;
  }
  div {
    font-weight: 400;
  }
</style>
