<template>
  <div class="content-wrap">
    <div v-if="showModal && user.status === 'ACTIVE'" class="modal-wrap">
      <div v-click-outside="() => (showModal = false)" class="modal">
        <div class="modal-header">
          <div class="modal-title">
            <div class="modale-title-error">
              <img src="/img/app/error.svg" alt="" />
            </div>
            {{ user.fullName }}
          </div>
          <img src="/img/app/close.svg" alt="" class="close-modal" @click="showModal = false" />
        </div>
        <div class="modal-content">
          <div class="radio-btn-list">
            <div
              v-for="(reasonsBanItem, idx) in reasonsBanList"
              :key="idx"
              class="radio-btn-item"
              @click="curentResonBan = reasonsBanItem"
            >
              <div :class="['input-select', { 'input-select-selected': curentResonBan === reasonsBanItem }]">
                <div class="input-select-circle" />
              </div>
              {{ reasonsBanItem }}
            </div>
          </div>
          <textarea v-model="banReasonText" class="text-area" placeholder="Type your message..." />
        </div>
        <div class="modal-footer">
          <button class="cancel-btn" @click="showModal = false">Cancel</button>
          <button class="btn-danger" @click="banUser">Yes, banned</button>
        </div>
      </div>
    </div>

    <div v-if="showModal && user.status === 'BANNED'" class="modal-wrap unblock-modal-wrap">
      <div v-click-outside="() => (showModal = false)" class="modal">
        <div class="modal-header">
          <img src="/img/app/close.svg" alt="" class="close-modal" @click="showModal = false" />
        </div>
        <div class="modal-content">
          <div class="attention-wrap" />
          <div class="unblock-title">Unblock {{ user.fullName }}</div>
        </div>
        <div class="modal-footer">
          <button class="btn-primary" @click="unbanUser">Yes, unblock</button>
        </div>
      </div>
    </div>

    <div v-if="loadData">
      <div>
        <h1>{{ user.fullName }}</h1>

        <div class="user-table-wrap">
          <div class="user-table-row">
            <div class="user-table-left">Full name</div>
            <div class="user-table-right">{{ user.fullName }}</div>
          </div>

          <div class="user-table-row">
            <div class="user-table-left">Username</div>
            <div class="user-table-right">{{ user.username }}</div>
          </div>

          <div class="user-table-row">
            <div class="user-table-left">Mail</div>
            <div class="user-table-right">{{ user.email }}</div>
          </div>

          <div class="user-table-row">
            <div class="user-table-left">Phone</div>
            <div class="user-table-right">{{ user.phone }}</div>
          </div>

          <div class="user-table-row">
            <div class="user-table-left">Last Login</div>
            <div class="user-table-right">{{ formatDate(user.lastActivityDate) }}</div>
          </div>

          <div class="user-table-row">
            <div class="user-table-left">Status</div>
            <div :class="['user-table-right user-table-role', { 'user-table-role-active': user.status === 'ACTIVE' }]">
              <div>{{ user.status }}</div>
              <img
                v-if="user.status === 'ACTIVE'"
                src="/img/app/lock.svg"
                alt=""
                class="lock-ico"
                @click="showModal = true"
              />
              <img v-else src="/img/app/unlock.svg" alt="" class="lock-ico" @click="showModal = true" />
            </div>
          </div>
        </div>

        <div class="btn-wrap">
          <button class="btn-primary" @click="save">Save</button>
          <router-link to="/users" class="cancel-btn">Cancel</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import dayjs from 'dayjs'
  var relativeTime = require('dayjs/plugin/relativeTime')
  dayjs.extend(relativeTime)

  export default {
    name: 'User',
    data() {
      return {
        user: {},
        loadData: false,

        showModal: false,
        reasonsBanList: ['Причина 1', 'Причина 2', 'Причина 3', 'Причина 4', 'Причина 5', 'Other'],
        curentResonBan: 'Причина 1',
        banReasonText: '',
      }
    },
    async created() {
      this.user = await this.$api.users.get(this.$route.params.id)
      this.loadData = true
    },
    methods: {
      save() {
        this.$router.push({ name: 'Users' })
      },
      async banUser() {
        let data = {
          banReason: this.curentResonBan,
          banDate: new Date(),
        }

        if (this.banReasonText) {
          data.banReason = this.banReasonText
        }

        let res = await this.$api.users.ban(this.user.id, data)

        let alertData = {}
        if (res instanceof Error) {
          alertData.alertType = 'error'
          alertData.alertMessage = 'Something went wrong'
          this.$store.commit('setAlert', alertData)
        } else {
          alertData.alertType = 'success'
          alertData.alertMessage = 'user successfully banned'
          this.$store.commit('setAlert', alertData)
          this.user.status = res.status
        }

        this.curentResonBan = this.reasonsBanList[0]
        this.banReasonText = ''
        this.showModal = false
      },
      async unbanUser() {
        let res = await this.$api.users.unban(this.user.id)
        this.user.status = res.status
        this.showModal = false

        let alertData = {}
        if (res instanceof Error) {
          alertData.alertType = 'error'
          alertData.alertMessage = 'Something went wrong'
          this.$store.commit('setAlert', alertData)
        } else {
          alertData.alertType = 'success'
          alertData.alertMessage = 'user unlocked successfully'
          this.$store.commit('setAlert', alertData)
          this.user.status = res.status
        }
      },
      formatDate(date) {
        return dayjs(date).format('MMMM DD, YYYY')
      },
    },
  }
</script>

<style lang="scss" scoped src="../assets/scss/users/edit-user.scss" />
