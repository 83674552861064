import { getRequest, postRequest, getRequestVoD, postRequestVoD } from './requests'
const routePath = 'uploader/admin'

export default {
  async getMimetype() {
    return await getRequest(`${routePath}/mimetype`)
  },
  async getFiles() {
    return await getRequest(`${routePath}/file`)
  },
  async uploadFile(data) {
    return await postRequest(`${routePath}/azure`, data)
  },
  async getVoD(params) {
    return await getRequestVoD('vodUpload', { params })
  },
  async uploadVoD(data) {
    return await postRequestVoD('vodUpload', data)
  },
}
