import { postRequest, putRequest } from './requests'

const routePath = 'auth/sessions/admin'

export default {
  async updatePassword(data) {
    return await putRequest(`${routePath}/password`, data)
  },
  async requestResetPassword(data) {
    return await postRequest(`${routePath}/passwordReset`, data)
  },
  async resetPassword(code, data) {
    return await putRequest(`${routePath}/password/${code}`, data)
  },
  async registrationSetPassword(code, data) {
    return await postRequest(`${routePath}/password/${code}`, data)
  },
}
