import { postRequest, getRequest, putRequest, deleteRequest } from './requests'

const routePath = 'admin/admins'

export default {
  async profile() {
    return await getRequest(`${routePath}/profile`)
  },
  // async editProfile(data) {
  //     return await putRequest(`${routePath}/profile`, data)
  // },
  async getAll(params) {
    return await getRequest(`${routePath}`, { params })
  },
  async get(id) {
    return await getRequest(`${routePath}/${id}`)
  },
  async delete(id) {
    return await deleteRequest(`${routePath}/${id}`)
  },
  async invite(data) {
    return await postRequest(`${routePath}/invite`, data)
  },
  // async editEmail(data) {
  //     return await putRequest(`${routePath}/profile/email`, data)
  // },
  async ban(id, banReason) {
    return await putRequest(`${routePath}/${id}/ban`, banReason)
  },
  async unban(id) {
    return await deleteRequest(`${routePath}/${id}/ban`)
  },
  async restore(id) {
    return await putRequest(`${routePath}/${id}/restore`)
  },
  async edit(id, data) {
    return await putRequest(`${routePath}/${id}/edit`, data)
  },
}
