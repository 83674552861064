<template>
  <div class="page-default page-broadcasts">
    <v-app>
      <v-card-title>
        Broadcasts
        <v-spacer />
        <v-text-field
          v-model="searchText"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          @keyup.enter="sendSearch"
        />
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="broadcasts"
        :options.sync="options"
        :server-items-length="totalItrms"
        :loading="loading"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :footer-props="rowsPerPageItems"
        :items-per-page="itemsPerPage"
        item-key="id"
        class="elevation-1"
      >
        <template v-slot:[`item.name`]="{ item }">
          <img
            :src="item.previewPath"
            alt=""
            style="width: 42px; height: 32px; border-radius: 5px; float: left; object-fit: cover"
          />
          <div class="table-sub-title table-sub-title--short" style="margin: 8px 0 0 6px; float: left">
            {{ item.name }}
          </div>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
          <div>{{ formatDate(item.createdAt) }}</div>
          <div class="table-sub-title">{{ formatDateAgo(item.createdAt) }}</div>
        </template>
        <template v-slot:[`item.scheduledStartDate`]="{ item }">
          <div>{{ formatDate(item.scheduledStartDate) }}</div>
          <div class="table-sub-title">{{ formatDateAgo(item.createdAt) }}</div>
        </template>
        <template v-slot:[`item.categories`]="{ item }">
          <span v-for="(cat, idx) in item.categories" :key="idx" class="test-111">
            {{ cat.title }}{{ idx !== item.categories.length - 1 ? ',' : '' }}
          </span>
        </template>
      </v-data-table>
    </v-app>
  </div>
</template>
<script>
  export default {
    name: 'Broadcasts',
    data() {
      return {
        broadcasts: [],
        meta: {},
        params: {
          searchLike: null,
          sort: 'id',
          order: 'DESC',
          take: 10,
          page: 1,
        },
        headers: [
          { text: 'id', value: 'id' },
          { text: 'name', value: 'name' },
          { text: 'Categories', value: 'categories' },
          { text: 'userId', value: 'userId' },
          { text: 'status', value: 'status' },
          { text: 'gcoreStatus', value: 'gcoreStatus' },
          { text: 'isPlanned', value: 'isPlanned' },
          { text: 'type', value: 'type' },
          { text: 'access', value: 'access' },
          { text: 'followers', value: 'followersCount' },
          { text: 'sponsors', value: 'sponsorsCount' },
          // { text: 'endDate', value: 'endDate' },
          // { text: 'startDate', value: 'startDate' },
          // { text: 'deleted', value: 'deleted' },
          { text: 'scheduled', value: 'scheduledStartDate' },
          { text: 'created At', value: 'createdAt' },
          // { text: 'Actions', value: 'actions', sortable: false },
        ],

        searchText: '',
        sortBy: 'id',
        sortDesc: true,
        itemsPerPage: 10,
        totalItrms: 0,
        options: {},
        rowsPerPageItems: { 'items-per-page-options': [10, 25, 50, 100] },
        loading: true,
        onSend: false,
      }
    },
    watch: {
      options: {
        handler() {
          this.getData()
        },
        deep: true,
      },
    },
    mounted() {
      for (let prop in this.$route.query) {
        this.params[prop] = this.$route.query[prop]
      }

      if (this.params.searchLike) {
        this.searchText = this.params.searchLike
      }
      if (this.params.page) {
        this.options.page = +this.params.page
      }
      if (this.params.take) {
        this.itemsPerPage = +this.params.take
        this.options.itemsPerPage = this.params.take
      }
      if (this.params.sort) {
        this.sortBy = this.params.sort
        this.options.sortBy = this.params.sort
      }
      if (this.params.order) {
        if (this.params.order === 'DESC') {
          this.options.sortDesc = true
          this.sortDesc = true
        } else {
          this.options.sortDesc = false
          this.sortDesc = false
        }
      }
      this.getFirstData()
    },
    methods: {
      async getData() {
        if (this.onSend) return false
        this.onSend = true
        this.loading = true

        const { sortBy, sortDesc, page, itemsPerPage } = this.options

        this.params.take = itemsPerPage
        this.params.page = page
        this.params.sort = sortBy[0]

        if (sortDesc[0] === true) {
          this.params.order = 'DESC'
        } else if (sortDesc[0] === false) {
          this.params.order = 'ASC'
        } else {
          this.params.sort = 'id'
          this.params.order = 'DESC'
        }

        if (this.params.searchLike === null) {
          let query = {
            take: this.params.take,
            page: this.params.page,
            sort: this.params.sort,
            order: this.params.order,
          }
          this.$router.push({ name: 'Broadcasts', query })
        } else {
          this.$router.push({ name: 'Broadcasts', query: this.params })
        }

        let res = await this.$api.streams.getAllBroadcasts(this.params)

        this.broadcasts = res.data
        this.totalItrms = res.meta.itemCount
        this.onSend = false
        this.loading = false
      },
      async getFirstData() {
        this.onSend = true
        this.loading = true

        let res = await this.$api.streams.getAllBroadcasts(this.params)

        this.broadcasts = res.data
        this.totalItrms = res.meta.itemCount

        console.log('broadcasts', this.broadcasts)

        this.onSend = false
        this.loading = false
      },
      sendSearch() {
        this.options.page = 1
        if (this.params.searchLike === '') {
          this.params.searchLike = null
        } else {
          this.params.searchLike = this.searchText
        }
        this.getData()
      },
      formatStatus(status) {
        if (status === 'ACTIVE') {
          return 'Active'
        } else if (status === 'BANNED') {
          return 'Banned'
        }
        return 'Undefined'
      },
      formatDate(date) {
        return this.$dayjs(date).format('MMMM DD, YYYY')
      },
      formatDateAgo(date) {
        return this.$dayjs(date).fromNow()
      },
      //vuetify
      toggleOrder() {
        this.sortDesc = !this.sortDesc
      },
      nextSort() {
        let index = this.headers.findIndex((h) => h.value === this.sortBy)
        index = (index + 1) % this.headers.length
        this.sortBy = this.headers[index].value
      },
    },
  }
</script>

<style lang="scss" scoped src="@/assets/scss/layout/page.scss" />
