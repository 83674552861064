import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    defaultTitle: 'Fitliga CRM',
    admin: {
      fullName: localStorage.getItem('fullName'),
      email: localStorage.getItem('email'),
      role: localStorage.getItem('role'),
    },
    showHeaderNavbar: true,

    alertShow: false,
    alertMessage: '',
    alertType: 'info',
  },
  mutations: {
    setUser(state, admin) {
      localStorage.setItem('fullName', admin.fullName || state.admin.fullName)
      localStorage.setItem('email', admin.email || state.admin.email)
      localStorage.setItem('role', admin.role || state.admin.role)
      Object.assign(state.admin, admin)
    },

    logOut(state) {
      localStorage.removeItem('fullName')
      localStorage.removeItem('email')
      localStorage.removeItem('role')
      localStorage.removeItem('token')

      state.admin.fullName = ''
      state.admin.email = ''
      state.admin.role = ''
      state.isAdmin = false
    },
    setShowHeaderNavbar(state, status) {
      state.showHeaderNavbar = status
    },
    setAlert(state, data) {
      state.alertMessage = data.alertMessage
      state.alertType = data.alertType
      state.alertShow = true

      setTimeout(function () {
        state.alertMessage = ''
        state.alertType = 'info'
        state.alertShow = false
      }, 5000)
    },
  },
})
