<template>
  <div class="page-default page-broadcast-cats">
    <v-app>
      <v-data-table
        :key="dataTableKey"
        :headers="headers"
        :items="videos"
        item-key="id"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :footer-props="rowsPerPageItems"
        :items-per-page="10"
        class="elevation-1"
      >
        <!-- TABLE DATA -->
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex">
            <v-icon class="mr-2" small @click="approveItem(item)"> mdi-check </v-icon>
            <v-icon class="mr-2" small @click="declineItem(item)"> mdi-close </v-icon>
            <v-icon v-if="isItemHasVod(item)" @click="openVodPlayer(item)"> mdi-play </v-icon>
          </div>
        </template>

        <template v-slot:[`item.title`]="{ item }">
          <div style="display: flex; align-items: center">
            <img
              :src="`${item.previewPath}`"
              style="width: 42px; height: 32px; border-radius: 5px; object-fit: cover; margin-right: 10px"
            />
            <div class="table-sub-title table-sub-title--short" style="">
              {{ item.name }}
            </div>
          </div>
        </template>
        <template v-slot:[`item.author`]="{ item }">
          <div class="table-sub-title" style="margin: 8px 0 0 6px; float: left">
            {{ item.channelIds ? setChannel(item.channelIds[0]) : '' }}
          </div>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
          <div>{{ formatDate(item.createdAt) }}</div>
          <div class="employee-table-sub-title">
            {{ formatDateAgo(item.createdAt) }}
          </div>
        </template>
        <template v-slot:[`item.description`]="{ item }">
          <div>
            {{ item.description.length > 100 ? item.description.slice(0, 100) : item.description }}
          </div>
        </template>
        <template v-slot:[`item.categories`]="{ item }">
          <div>
            <span v-for="(cat, idx) in item.categories" :key="idx" class="test-111">
              {{ cat.title }}{{ idx !== item.categories.length - 1 ? ',' : '' }}
            </span>
          </div>
        </template>
      </v-data-table>
    </v-app>

    <br />
    <br />
    <br />
    <v-dialog v-model="dialogs.decline" width="600">
      <v-card>
        <v-card-title class="headline mt-0">Decline broadcast</v-card-title>

        <v-card-text>
          <v-container>
            <v-textarea v-model="banReason" label="Ban reason" />
            <div v-if="!$v.banReason.required && $v.banReason.$error" class="msg-error">
              <img src="/img/app/error.svg" alt="" class="error-icon" />
              Ban reason is required
            </div>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="cancelDeclineStream"> Cancel </v-btn>
          <v-btn color="blue darken-1" text @click="sendBanReq"> Send </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogs.approve" width="600">
      <v-card>
        <v-card-title class="headline mt-0">Approve broadcast</v-card-title>

        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="cancelApproveStream"> Cancel </v-btn>
          <v-btn color="blue darken-1" text @click="sendApproveReq"> Approve </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { required } from 'vuelidate/lib/validators'

  export default {
    name: 'Moderation',
    data() {
      return {
        videos: [],
        meta: {},

        params: {
          searchLike: null,
          sort: 'id',
          order: 'DESC',
          take: 50,
          page: 1,
          status: 'WAIT_FOR_APPROVE',
        },
        headers: [
          { text: 'id', value: 'id', align: 'start' },
          { text: 'title', value: 'title', sortable: false },
          { text: 'description', value: 'description', sortable: false },
          { text: 'categories', value: 'categories', sortable: false, width: '300px' },
          { text: 'channel name', value: 'author', sortable: false },
          { text: 'created At', value: 'createdAt', sortable: false },
          { text: 'actions', value: 'actions', sortable: false },
        ],

        dialogs: {
          decline: false,
          approve: false,
        },

        dataTableKey: 0,

        banReason: '',

        initialCategoryIds: [],
        addCategoryIds: [],
        removeCategoryIds: [],

        newStream: {},
        categories: [],
        channels: [],

        editedIndex: -1,

        editedItemId: null,

        totalItems: 0,
        searchText: '',
        sortBy: 'id',
        sortDesc: false,
        itemsPerPage: 50,
        options: {},
        rowsPerPageItems: { 'items-per-page-options': [10, 25, 50, 100] },
        loading: true,

        onSend: false,
      }
    },
    watch: {
      options: {
        handler() {
          this.getData()
        },
        deep: true,
      },
    },
    mounted() {
      for (let prop in this.$route.query) {
        this.params[prop] = this.$route.query[prop]
      }

      if (this.params.searchLike) {
        this.searchText = this.params.searchLike
      }
      if (this.params.page) {
        this.options.page = +this.params.page
      }
      if (this.params.take) {
        this.itemsPerPage = this.params.take
        this.options.itemsPerPage = this.params.take
      }
      if (this.params.sort) {
        this.sortBy = this.params.sort
        this.options.sortBy = this.params.sort
      }
      if (this.params.order) {
        if (this.params.order === 'DESC') {
          this.options.sortDesc = true
          this.sortDesc = true
        } else {
          this.options.sortDesc = false
          this.sortDesc = false
        }
      }
      this.getFirstData()
    },
    methods: {
      async getData() {
        if (this.onSend) return false
        this.onSend = true
        this.loading = true
        console.log(this.options)
        const { sortDesc, page, itemsPerPage } = this.options

        this.params.take = itemsPerPage
        this.params.page = page
        this.params.sort = 'id'

        if (sortDesc[0] === true) {
          this.params.order = 'DESC'
        } else if (sortDesc[0] === false) {
          this.params.order = 'ASC'
        } else {
          this.params.sort = 'id'
          this.params.order = 'ASC'
        }

        if (this.params.searchLike === null) {
          let query = {
            take: this.params.take,
            page: this.params.page,
            sort: this.params.sort,
            order: this.params.order,
          }
          this.$router.push({ name: 'Moderation', query })
        } else {
          this.$router.push({ name: 'Moderation', query: this.params })
        }
        let res = await this.$api.streams.getAllBroadcasts(this.params)

        this.videos = res.data
        this.totalItems = res.meta.itemCount
        this.onSend = false
        this.loading = false
      },
      async getFirstData() {
        this.onSend = true
        this.loading = true

        console.log('this params', this.params)
        let res = await this.$api.streams.getAllBroadcasts({ ...this.params })
        console.log('🚀 ~ file: VideosOnDemand.vue ~ line 300 ~ getFirstData ~ res', res)

        this.videos = res.data
        this.totalItems = res.meta.itemCount

        console.log('this.videos', this.videos)
        console.log('data', res.data)

        let channels = await this.$api.channels.getAll({ take: 500 })
        this.channels = channels.data

        let cats = await this.$api.streams.getCategories({ take: 500 })
        this.categories = cats.data

        this.onSend = false
        this.loading = false
      },
      setChannel(channelId) {
        let channel = this.channels.find((channel) => channel.id === channelId)
        return channel?.name
      },
      formatDate(date) {
        return this.$dayjs(date).format('MMMM DD, YYYY')
      },
      formatDateAgo(date) {
        return this.$dayjs(date).fromNow()
      },

      openVodPlayer(item) {
        if (this.isItemHasVod(item)) {
          this.$router.push({
            name: 'ModerationPlayer',
            params: { streamId: item.streams[0].id, broadcastId: item.id },
          })
        }
      },

      approveItem(item) {
        this.editedIndex = this.videos.indexOf(item)
        this.editedItemId = item.id
        this.editedItem = Object.assign({}, item)

        this.dialogs.approve = true
      },
      async declineItem(item) {
        console.log('delete', item)
        this.editedIndex = this.videos.indexOf(item)
        this.editedItemId = item.id
        this.editedItem = Object.assign({}, item)

        this.dialogs.decline = true
      },

      async sendBanReq() {
        this.$v.$touch()

        if (this.$v.$invalid) {
          return false
        }

        const banRes = await this.$api.streams.banBroadcast(this.editedItemId, {
          banReason: this.banReason,
          banDate: new Date().toISOString(),
        })

        if (banRes) {
          this.dialogs.decline = false
          this.videos = this.videos.filter((broadcast) => broadcast.id !== this.editedItemId)
          this.totalItems -= 1

          if (this.videos.length === 0) {
            this.getData()
          }
        }
      },
      async sendApproveReq() {
        const approveRes = await this.$api.streams.approveBroadcast(this.editedItemId)

        if (approveRes) {
          this.dialogs.approve = false

          this.videos = this.videos.filter((broadcast) => broadcast.id !== this.editedItemId)
          this.totalItems -= 1

          if (this.videos.length === 0) {
            this.getData()
          }
        }
      },

      restoreItem(item) {
        this.editedIndex = this.videos.indexOf(item)
        this.editedCategoryId = item.id
        this.editedItem = Object.assign({}, item)
      },

      cancelDeclineStream() {
        this.dialogs.decline = false
        this.banReason = ''
      },
      cancelApproveStream() {
        this.dialogs.approve = false
      },

      isItemHasVod(item) {
        return item.streams[0]?.vodUrl
      },

      //vuetify
      toggleOrder() {
        this.sortDesc = !this.sortDesc
      },
      nextSort() {
        let index = this.headers.findIndex((h) => h.value === this.sortBy)
        index = (index + 1) % this.headers.length
        this.sortBy = this.headers[index].value
      },
    },
    validations: {
      banReason: {
        required,
      },
    },
  }
</script>

<style lang="scss">
  input {
    border: none;
  }
  th.text-start {
    text-align: left;
  }
  .elevation-1 .text-start {
    font-weight: 600;
    font-size: 12px !important;
    line-height: 16px;
    color: #000000;
  }
  .v-data-table__wrapper {
    margin-top: 0;
    border-radius: 4px;
    border: 1px solid #c2c9d1;
    border-bottom: none;
  }
  .v-data-table-header {
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.5) !important;
    // padding: 26px 16px 14px;
    border-bottom: 1px solid #c2c9d1;
    border-right: 1px solid #c2c9d1;
    background-repeat: no-repeat;
    background-position: top 31px right 22px;
    cursor: pointer;
    position: relative;
  }
  .text-start a {
    color: #000000;
  }
  .sortable:before {
    content: '';
    position: absolute;
    width: 32px;
    height: 10px;
    right: 0px;
    background: url(/img/app/triangle.svg) 0 0 no-repeat;
  }
  .sortable.desc:before {
    background: url(/img/app/triangle-blue.svg) 0 0 no-repeat;
  }
  .sortable.asc:before {
    background: url(/img/app/triangle-blue.svg) 0 0 no-repeat;
    transform: rotate(180deg);
    background-position: 22px 6px;
  }
  th.text-start.sortable {
    position: relative;
  }
  .v-data-table-header__icon {
    display: none !important;
  }
  label {
    margin-top: 0px;
  }
  body .v-application .elevation-1 {
    box-shadow: none !important;
  }
  body .v-divider--vertical.v-divider--inset {
    display: none;
  }
  .file-prevew {
    height: 34px;
    width: auto;
    background-size: contain;
  }
  .progress-wrap {
    height: 6px;
  }
</style>

<style lang="scss" scoped>
  @import '@/assets/scss/layout/page.scss';
</style>
