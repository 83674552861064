import request from './requests'
import employees from './employees'
import profile from './profile'
import users from './users'
import channels from './channels'
import reports from './reports'
import streams from './streams'
import uploader from './uploader'
import legal from './legal'

export default {
  login: request.login,
  logout: request.logout,
  employees,
  profile,
  users,
  channels,
  reports,
  streams,
  uploader,
  legal,
}
