<template>
  <div class="player-container">
    <video id="video" controls />
  </div>
</template>

<script>
  import Hls from 'hls.js'

  export default {
    props: {
      videoUrl: {
        type: String,
        default: '',
      },
    },
    mounted() {
      this.playVideo()
    },
    methods: {
      playVideo() {
        const video = document.getElementById('video')
        const videoSrc = this.videoUrl
        if (Hls.isSupported()) {
          const hls = new Hls()
          hls.loadSource(videoSrc)
          hls.attachMedia(video)
        } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
          video.src = videoSrc
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .player-container {
    width: 100%;
    height: 100%;

    #video {
      width: 100%;
      height: 100%;
    }
  }
</style>
