import axios from 'axios'
import store from '@/store'

const api = axios.create({
  baseURL: process.env.VUE_APP_API,
})

const API_LIST = [api]

API_LIST.forEach((api) => {
  api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token')
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`
        // config.headers.post['Content-Type'] = 'multipart/formdata'
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )
})

async function login(admin) {
  const res = await postRequest('auth/sessions/admin/loginPassword', admin)
  if (res instanceof Error) {
    return res
  }
  localStorage.setItem('token', res.token.token)
  localStorage.setItem('email', res.admin.email)
  localStorage.setItem('fullName', res.admin.fullName)

  store.commit('setUser', res.admin)

  return res
}

function logout() {
  localStorage.removeItem('token')
  return true
}

export default {
  login,
  logout,
}

export async function getRequest(path, params = {}) {
  try {
    return (await api.get(path, params)).data
  } catch (e) {
    return e
  }
}

export async function postRequest(path, data, params = {}) {
  try {
    return (await api.post(path, data, params)).data
  } catch (e) {
    throw e.response?.data || e
  }
}

export async function putRequest(path, data, params = {}) {
  try {
    return (await api.put(path, data, params)).data
  } catch (e) {
    return e
  }
}

export async function deleteRequest(path, params = {}) {
  try {
    return (await api.delete(path, params)).data
  } catch (e) {
    return e
  }
}

// VoD

export async function getRequestVoD(path, params = {}) {
  try {
    return (await api.get(path, params)).data
  } catch (e) {
    return e
  }
}

export async function postRequestVoD(path, data, params = {}) {
  try {
    return (await api.post(path, data, params)).data
  } catch (e) {
    return e
  }
}
